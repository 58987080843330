import React, { useState } from "react";
import { Modal, Sheet, ModalClose, Typography, Button } from "@mui/joy";
import { NoteTypeSelect } from "../custom/NoteTypeSelect";
import { NoteSections } from "../../utils/constants/Notes";
import { create_generate_note } from "../../services/GenerateNote";
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from "@tanstack/react-query";


export function AddNoteModal({ open, closePopup, visit_id}) {
    const [ note_type, set_note_type ] = useState();
    const queryClient = useQueryClient();

    const mutate_add_note = useMutation({
        mutationFn: create_generate_note,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['visit', visit_id]})
            queryClient.invalidateQueries({ queryKey: ['visits']})
        },
        onError: (error) => {
            throw new Error(error)
        }
    })

    const handleSubmit = (event) => {
        event.preventDefault();
        mutate_add_note.mutate({
            visit_id: visit_id,
            note_type: note_type,
        })
        closePopup();
    }

    return <Modal aria-labelledby="modal-title"
            aria-describedby="modal-desc" open={open} onClose={closePopup} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Sheet variant="outlined"
          sx={{
            maxWidth: 800,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            display: "flex",
            flexDirection: "column",
          }}>
            <ModalClose variant="plain" sx={{ m: 1, width: "fit-content" }} />
            <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
            >
                Add new note to your visit
            </Typography>
            <Typography id="modal-desc" textColor="text.tertiary" paddingBottom='10px'>
                Choose a new note type to add to your visit!
            </Typography>
            <form onSubmit={handleSubmit}>
                <NoteTypeSelect disabled={false} calmTitle={false} noteViews={NoteSections.show_all_note_sections_no_pdf_no_beta()} value={note_type} 
                sx={{
                    width: "100%",
                    mb: 2
                }}
                onChange={(_, note_given) => {
                    set_note_type(note_given)
                }} required/>
                <Button type="submit">CONFIRM</Button>
            </form>
        </Sheet>
    </Modal>
}


AddNoteModal.propTypes = {
    open: PropTypes.bool.isRequired,
    closePopup: PropTypes.func.isRequired,
    visit_id: PropTypes.string.isRequired,
    reload: PropTypes.func,
}