// React and Third-Party Libraries
import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
// Material-UI Components
import { 
    Typography, 
    Sheet, 
    Box, 
    CardContent,
    Card, 
    CardActions,
    Button,
    Divider, 
    Input, 
    Chip, 
    LinearProgress,
    Tooltip, 
} from "@mui/joy";

// Material-UI Icons
import CircleIcon from '@mui/icons-material/Circle';
import PersonIcon from '@mui/icons-material/Person';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { DiamondPlus } from "lucide-react";

// Custom Components
import { NoteTypeSelect } from "../components/custom/NoteTypeSelect";
import MicrophoneSelect from "../components/custom/audio/MicSelect";
import TimerComponent from "../components/custom/Timer";
import AudioDisplay from "../components/custom/audio/AudioDisplay";
import UploadTranscriptPopup from "../components/modals/UploadAudio";
import NameAppointmentPopup from "../components/modals/NameAppointmentPopup";
import NavBlocker from "../components/modals/NavBlocker";
import { useNewVisitTour } from "../tour/useNewVisitTour";
import Joyrideconfig from "../tour/Joyrideconfig";

// Context
import { AlertContext } from "../context/AlertFlag";

// Hooks
import { useFetchUserPreferences } from "../hooks/api/UserPreferences";

// Utilities
import { 
    check_note_status,
    check_visit_status,
    checkVisitAllowed, 
} from "../utils/NewVisitUtils";
import { 
    grab_wakelock, 
} from "../utils/AudioUtils";
import { 
    getPresignedURL, 
    putAudioIntoPresigned, 
    putIntoMultipartPresigned
} from "../utils/AWSHelpers";

// Services
import { post_visit } from "../services/VisitRouter";

// Redux
import { useDispatch } from "react-redux";
import { addGenVisit } from "../redux/reducers/visitReducer";

// Styling
import '../styling/NewVisitTour.css';
import HandleNullAudioModal from "../components/modals/AudioNullModal";
import { motion, AnimatePresence } from "framer-motion";
import mixpanel from "mixpanel-browser";
import { InputLanguageSelect } from "../components/custom/InputLanguageSelect";
import { put_user_preferences } from "../services/UserPreferences";
import { useMicrophone, useRecorder } from "../context/MicContext";
import { get_user_account } from "../services/UserAccount";
import { useQuery } from "@tanstack/react-query";


function NewVisitPage() {
// Context and Navigation
const { addAlert } = useContext(AlertContext);
const { isReady } = useMicrophone();
const { initializeRecording, startRecording, pauseRecording, unPauseRecording, stopRecording, recordingState, recordingTime, cleanUpRecording, prepForNextVisit } = useRecorder();
const navigate = useNavigate();

// User and Preferences
const { preferences } = useFetchUserPreferences();
const { data: user, isLoading: loadingUser } = useQuery({
    queryKey: ['user'],
    queryFn: get_user_account,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
})

// Redux
const dispatch = useDispatch();

// State Management
const [recording, setRecording] = useState(false);
const [audioReady, setAudioReady] = useState(isReady);
const [title, setTitle] = useState("");
const [debouncedTitle, setDebouncedTitle] = useState("");
const [noteType, setNoteType] = useState();
const [generating, setGenerating] = useState(false);
const [progress, setProgress] = useState(0);
const [next_visit, set_next_visit] = useState(false);
const [uploadAudioModal, setUploadAudioModal] = useState(false);
const [nameVisitModal, setNameVisitModal] = useState(false);
const [canStartTour, setCanStartTour] = useState(false); 

const [audioDetectionModal, setAudioDetectionModal] = useState(false);

const abort_signal = useRef(new AbortController());

// Refs
const wakeLock = useRef(null);
const fileRef = useRef(null);

const intervalRef = useRef(null);
const cancelRef = useRef(null);

//Tour (useNewVisitTour.js)
const {
    runTour,
    setRunTour,
    stepIndex,
    setStepIndex,
    steps,
    handleJoyrideCallback
  } = useNewVisitTour({
    user,
    loadingUser,
    recordingTime,
    title,
    debouncedTitle,
    setDebouncedTitle,
  });

    const reset = () => {
        // For current generation
        setGenerating(false);
        cleanUpRecording();
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        cancelRef.current = true;

        // Audio and others
        setTitle("");
        setProgress(0);
        setRecording(false);
        abort_signal.current.abort("User triggered reset");
        abort_signal.current = new AbortController();
        fileRef.current = null;
        prepForNextVisit();
        set_next_visit(false);
    }

    const toggleMicrophone = async () => {
        try {
            // Play
            if (recordingState === 'inactive') {
                await checkVisitAllowed(recordingTime, addAlert);
                await initializeRecording();
                wakeLock.current = await grab_wakelock();
                startRecording();
                if (runTour && steps[stepIndex]?.target === ".tour-start-capturing") {
                    setStepIndex(stepIndex + 1);
                }
            } 
            // UnPause
            else if (recordingState === 'paused') {
                if (recordingTime >= 9000) {
                    addAlert("Limit of 2 hours reached", "danger");
                    return;
                }
                unPauseRecording();
                setRecording(true);
            } 
            // Pause
            else if (recordingState === 'recording'){
                pauseRecording();
                setRecording(false);
            }
        } catch(error) {
            throw new Error(error)
        }
    }

    function check_note_allowed() {
        if (recordingTime < 20) {
            addAlert("Please record at least 20 seconds!", "danger");
            throw new Error("Minimum of 20 seconds not reached");
        } else if (generating) {
            addAlert("Note currently generating!", "danger");
            throw new Error("Note currently generating!");
        }
        return;
    }

    function update_progress_bar() {
        setProgress((prevProgress) => {
            if (prevProgress < 98) {
                const random = Math.random();
                return Math.min(prevProgress + random, 98); // Ensure it doesn't exceed 95
            }
            return prevProgress; // No change if progress is >= 95
        });
    }

    async function generate_note(name = null) {
        let audioBlob;
        try {
            cancelRef.current = false;
            setGenerating(true);
            setRecording(false);
            if (!fileRef.current) {
                check_note_allowed();
                audioBlob = stopRecording();
                if (wakeLock.current) {
                    wakeLock.current.release();
                    wakeLock.current = null;
                }
            } else {
                audioBlob = fileRef.current;
            }
            intervalRef.current = setInterval(() => {
                update_progress_bar();
            }, 150)
            let presigned_fields;
            if (audioBlob.size > (12 * 1024 * 1024)) {
                const object_name = await putIntoMultipartPresigned(audioBlob);
                presigned_fields = {
                    "fields": {
                        "key": object_name
                    }
                }
            } else {
                presigned_fields = await getPresignedURL();
                await putAudioIntoPresigned(presigned_fields, audioBlob);
            }

            const visit = await post_visit(presigned_fields, name ? name : title.trim(), noteType);
            mixpanel.track('Visit Triggered', {
                'Note Type': noteType,
                'Title': name ? name : title.trim(),
                'Blob Size': audioBlob.size,
                'State': 'Created',
                'Comments': 'Created successfully',
            })
            set_next_visit(true);
            dispatch(addGenVisit(visit));
            addAlert("Visit created!", "success");
            fileRef.current = null;
            await check_visit_status(visit.id, abort_signal.current.signal);
            await check_note_status(visit.id, visit.generate_notes[0].id, abort_signal.current.signal);
            setGenerating(false);
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            
            if (!cancelRef.current) {
                navigate(`/past-visits/${visit.id}`);
            }
        } catch (error) {
            mixpanel.track('Visit Triggered', {
                'Note Type': noteType,
                'Title': name ? name : title.trim(),
                'Blob Size': audioBlob?.size,
                'State': 'Failed',
                'Comments': error.message,
            })
            addAlert("Cannot generate note.", "danger");
            setGenerating(false);
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
            throw new Error(error);
        }
    }

    const raiseAudioError = () => {
        setAudioDetectionModal(true);
    }
    
    useEffect(() => {
        setAudioReady(isReady)
    }, [isReady])

    useEffect(() => {
        return () => {
            cleanUpRecording();
            if (wakeLock.current) {
                wakeLock.current.release();
                wakeLock.current = null;
            }
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
            cancelRef.current = true;
            abort_signal.current.abort("Unmounted component");
        };
    }, []); // Include these dependencies

    useEffect(() => {
        if (preferences) {
            setNoteType(preferences['note_type']);
        }
    }, [preferences])

    return <Sheet sx={{height: "100%", width: "100%", background: "white", overflow: "auto"}}>
            <Joyrideconfig
            steps={steps}
            runTour={canStartTour && runTour}
            stepIndex={stepIndex}
            handleJoyrideCallback={handleJoyrideCallback}
            />
            <Box
                sx={{
                    position: "absolute",
                    bottom: 16, 
                    left: 16, 
                    zIndex: 1100, 
                }}
                >
                <Button
                    onClick={() => setRunTour(true)}
                    size="sm"
                    variant="soft"
                    sx={{
                    borderRadius: "50%", 
                    minWidth: 40, 
                    height: 40,
                    display: "flex",
                    justifyContent: "center", 
                    alignItems: "center", 
                    backgroundColor: "var(--dark-blue-button)",
                    color: "white",
                    fontSize: "1.25rem", 
                    fontWeight: "600", 
                    "&:hover": {
                        backgroundColor: "var(--dark-blue-button-hover)",
                    },
                    }}
                >
                    ?
                </Button>
            </Box>
        {uploadAudioModal && <UploadTranscriptPopup open={uploadAudioModal} closePopup={() => {setUploadAudioModal(false)}} audioUploaded={(file) => {
            fileRef.current = file;
            if (title.trim().length <= 0) {
                setNameVisitModal(true);
            } else {
                generate_note();
            }
        }}/>}
        {nameVisitModal && <NameAppointmentPopup open={nameVisitModal} closePopup={() => {setNameVisitModal(false)}} handleName={(name) => {
            setTitle(name);
            generate_note(name);
            setNameVisitModal(false);
        }} />}
        <NavBlocker dirty={(recordingState !== 'inactive' && !generating) || (generating && !next_visit)}/>
        <HandleNullAudioModal open={audioDetectionModal} closePopup={() => { 
            setAudioDetectionModal(false)
            pauseRecording();
        }} />
        <Box sx={{display: "flex", px: 4, paddingTop: 2, paddingBottom: 1, alignItems: "center", gap: 2, height: { sx: "5%", md: "10%" }}}>
            <Typography level="h3"> New Visit </Typography>
            <Chip startDecorator={ <CircleIcon /> } size="sm" color={recording ? "neutral" : generating ? "warning" : audioReady ? "success" : "danger"} sx={{ height: "fit-content", p: 1 }}> 
                {recording ? "Recording..." : generating ? "Capturing..." : audioReady ? "Ready" : "Not Ready"}
            </Chip>
        </Box>
        <Divider />
        <Box sx={{p: 2, backgroundColor: "var(--joy-palette-neutral-50)", height: "80%"}}>
            <Box sx={{display: "flex", p: 2}}>
                <Card sx={{background: "white", width: "100%"}}>
                    <CardContent>
                        <Box sx={{width: "100%"}} className="tour-patient-name">
                            <Typography level="body-sm"> Patient Name </Typography>
                            <Input value={title} onChange={(event) => {setTitle(event.target.value)}} label="Patient Name" placeholder="Full Name" sx={{height: "42px"}} startDecorator={<PersonIcon />}/>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: {xs: 'column', lg: 'row'}, justifyContent: "space-between", gap: 2, py: 2}}>
                            <Box sx={{ width: { xs: "100%", lg: "100%" } }} className="tour-visit-type">
                                <Typography level="body-sm">Visit Type</Typography>
                                {preferences && (
                                    <NoteTypeSelect
                                    onChange={(_, noteSelected) => {
                                        setNoteType(noteSelected);
                                        // Move to the next tour step if the current step is ".tour-visit-type"
                                        if (runTour && steps[stepIndex]?.target === ".tour-visit-type") {
                                            setStepIndex(stepIndex + 1);
                                        }
                                    }}
                                    value={noteType}
                                    disabled={false}
                                    calmTitle={false}
                                    sx={{ width: "100%", alignSelf: "center" }}
                                    noteViews={preferences["note_views"]}
                                    />
                                )}
                            </Box>
                            {preferences && <Box sx={{ width: {xs: "100%", lg: "20%"} }}>
                                <Typography level="body-sm"> Conversation Lang. </Typography>
                                <InputLanguageSelect defaultValue={preferences['input_language']} onChange={ async (new_language) => { await put_user_preferences(preferences['split_hpi'], preferences['note_format'], preferences['note_type'], preferences['note_views'], new_language, preferences['output_language']) }} disabled={recording || generating}/>
                            </Box>}
                        </Box>
                    </CardContent>
                    <Divider sx={{mx: 2}}/>
                    <CardActions sx={{ alignItems: "end", width: "100%", flexDirection: { xs: "column", sm: "row" }, display: "flex"}}>
                        <MicrophoneSelect disabled={generating || recordingState === "recording"}/>
                        {(recordingState === 'inactive') && (
                            <Button
                                onClick={async () => {
                                await toggleMicrophone();
                                setRecording(true); // Update state to switch button
                                }}
                                startDecorator={<PlayCircleOutlineIcon />}
                                sx={{ display: "flex" }}
                                disabled={generating || !isReady}
                                className="tour-start-capturing"
                            >
                                Start Capturing
                            </Button>
                        )}
                        {recordingState === 'inactive' && <Button onClick={() => {
                                setUploadAudioModal(true);
                            }} startDecorator={<CloudUploadOutlinedIcon />} sx={{display: "flex", backgroundColor: "white", width: {xs: "100%", sm: "25%"}}} disabled={generating} variant="plain"> Upload Audio </Button>}
                        {recordingState === 'paused' && <Button onClick={async () => { await toggleMicrophone() }} startDecorator={<PlayArrowIcon />} sx={{display: "flex"}} disabled={generating} className="tour-pause-audio"> Unpause </Button>}
                        {recordingState === 'recording' && <Button onClick={async () => { await toggleMicrophone() }} startDecorator={<PauseIcon />} sx={{display: "flex"}} disabled={generating} className="tour-pause-audio"> Pause</Button>}
                        {recordingState !== 'inactive' && <Button onClick={() => {
                            if (title.trim().length <= 0) {
                                setNameVisitModal(true);
                            } else {
                                generate_note();
                                setStepIndex((prevStepIndex) => prevStepIndex + 1);
                            }
                        }} key={(recordingTime < 20 || generating) ? "disabled" : "enabled"} startDecorator={<NoteAddIcon />} sx={{display: "flex", backgroundColor: "var(--dark-blue-button)", '&:hover': {backgroundColor: 'var(--dark-blue-button-hover)', boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.2)" }}} className="tour-complete" disabled={recordingTime < 20 || generating}> Complete </Button>}
                    </CardActions>
                    <Divider sx={{m: 2}}/>
                    <Box sx={{ display: "flex", alignItems:"center", width: "100%" }}>
                        {recordingState === 'recording' && !generating && <Box sx={{ width: {xs: '10px', lg: '10px'}, height: {xs: '10px', lg: '10px'}, m: 1, backgroundColor: "red", borderRadius: "50%", alignSelf: "center", animation: "blink 1s infinite"}}/>}
                        {recordingState !== 'recording' && <Box sx={{ width: {xs: '10px', lg: '10px'}, height: {xs: '10px', lg: '10px'}, m: 1, backgroundColor: "grey", borderRadius: "50%", alignSelf: "center" }}/>}
                        {!generating && <TimerComponent seconds={recordingTime}/>}
                        {!generating && isReady &&  <AudioDisplay raiseAudioError={raiseAudioError}/>}
                        {generating && <LinearProgress determinate value={progress} sx={{ mx: 2 }} />}
                    </Box>
                    <Typography level="body-xs" sx={{ alignSelf: "center"}}> Make sure to obtain patient consent if you&apos;re using OneChart in an appointment. </Typography>
                </Card>
            </Box>

            <AnimatePresence>
                {next_visit && (
                    <motion.div
                    initial={{ opacity: 0, x: 50 }} 
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 50 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                    >
                        <Box sx={{ m: 2 }}>
                            <Tooltip title="Start a new visit while we work on your note in the background!">
                                <Button sx={{ display: "flex" }} startDecorator={<DiamondPlus />} onClick={() => {reset()}}> Start a new visit </Button>
                            </Tooltip>
                        </Box>
                    </motion.div>
                )}
            </AnimatePresence>
        </Box>

    </Sheet>
}

export default NewVisitPage;