import React, { useState, useRef, useContext, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AlertContext } from "../context/AlertFlag";
import { Sheet, Box, Typography, Chip, Divider, CardActions, Button, LinearProgress, Card, CardContent, CircularProgress, Input } from "@mui/joy";
import CircleIcon from '@mui/icons-material/Circle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { grab_wakelock } from "../utils/AudioUtils";
import TimerComponent from "../components/custom/Timer";
import MicrophoneSelect from "../components/custom/audio/MicSelect";
import AudioDisplay from "../components/custom/audio/AudioDisplay";
import HandleNullAudioModal from "../components/modals/AudioNullModal";
import ContinueVisitWithAuthCheck from "../components/authhoc/ContinueVisit";
import GenerateNotesListHorizontal from "../components/custom/GenerateNotesListHorizontal";
import { PureNoteText } from "../components/custom/PureNoteTextComponent";
import { chooseText, formatNoteName } from "../utils/FormatNoteNameAndText";
import { putAudioIntoPresigned, getPresignedURL, putIntoMultipartPresigned } from "../utils/AWSHelpers";
import { continue_visit, get_visit } from "../services/VisitRouter";
import { check_visit_status, checkVisitAllowed } from "../utils/NewVisitUtils";

import mixpanel from "mixpanel-browser";
import { NotepadText, User } from "lucide-react";
import NavBlocker from "../components/modals/NavBlocker";
import { useMicrophone, useRecorder } from "../context/MicContext";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

function ContinueVisitPage() {
    const { visitId } = useParams();
    const { isReady } = useMicrophone();
    const { initializeRecording, startRecording, pauseRecording, unPauseRecording, stopRecording, recordingState, recordingTime, cleanUpRecording, audioDeviceRef } = useRecorder();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const abort_signal = useRef(new AbortController());
    const navigate = useNavigate();
    const { data: visit, isLoading: visitLoading, error: visitError } = useQuery({
        queryKey: ['visit', visitId],
        queryFn: () => get_visit(visitId),
        enabled: !!visitId,
        refetchOnMount: true,
    });
    const queryClient = useQueryClient();
    const mutate_continue_visit = useMutation({
        mutationFn: continue_visit,
        onSuccess: () => {
            addAlert("Visit continued successfull!", "success");
            queryClient.invalidateQueries({ queryKey: ["visit", visitId]})
        },
        onError: (error) => {
            addAlert("Visit couldn't be continued", "danger");
            throw new Error(error);
        }
    })
    const { addAlert } = useContext(AlertContext);
    const [recording, setRecording] = useState(false);
    const [audioReady, setAudioReady] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [progress, setProgress] = useState(0);

    const [audioDetectionModal, setAudioDetectionModal] = useState(false);

    const [note, setNote] = useState(null);

    const wakeLock = useRef(null);

    const intervalRef = useRef(null);
    const cancelRef = useRef(null);
    
    const toggleMicrophone = async () => {
        try {
            // Play
            if (recordingState === 'inactive') {
                await checkVisitAllowed(recordingTime, addAlert);
                wakeLock.current = await grab_wakelock();
                await initializeRecording();
                startRecording();
            } 
            // UnPause
            else if (recordingState === 'paused') {
                if (recordingTime >= 9000) {
                    addAlert("Limit of 2 hours reached", "danger");
                    return;
                }
                unPauseRecording();
                setRecording(true);
            } 
            // Pause
            else {
                pauseRecording();
                setRecording(false);
            }
        } catch(error) {
            throw new Error(error)
        }
    }

    const handleChangeNoteByIndex = (index) => {
        if (!index && index !== 0) return;
        const note_id = visit.generate_notes[index]['id']
        setSearchParams({ generate_note_id: note_id })
        setNote(visit.generate_notes[index]);
    }

    function check_note_allowed() {
        if (recordingTime < 20) {
            addAlert("Please record at least 20 seconds!", "danger");
            throw new Error("Minimum of 20 seconds not reached");
        } else if (generating) {
            addAlert("Note currently generating!", "danger");
            throw new Error("Note currently generating!");
        }
        return;
    }


    function update_progress_bar() {
        setProgress((prevProgress) => {
            if (prevProgress < 98) {
                const random = Math.random();
                return Math.min(prevProgress + random, 98); // Ensure it doesn't exceed 95
            }
            return prevProgress; // No change if progress is >= 95
        });
    }

    async function continue_note() {
        let audioBlob;
        try {
            cancelRef.current = false;
            if (!cancelRef.current) {
                setGenerating(true);
                setRecording(false);
            }
            // setProgressStatus("Starting capture...")
            check_note_allowed();
            if (wakeLock.current) {
                wakeLock.current.release();
                wakeLock.current = null;
            }

            audioBlob = stopRecording();
            intervalRef.current = setInterval(() => {
                update_progress_bar();
            }, 150)
            let presigned_fields;
            if (audioBlob.size > (12 * 1024 * 1024)) {
                const object_name = await putIntoMultipartPresigned(audioBlob);
                presigned_fields = {
                    "fields": {
                        "key": object_name
                    }
                }
            } else {
                presigned_fields = await getPresignedURL();
                await putAudioIntoPresigned(presigned_fields, audioBlob);
            }
            mutate_continue_visit.mutate({ 
                presigned_fields: presigned_fields,
                visit_id: visitId,
            })
            mixpanel.track('Continue visit Triggered', {
                'Title': visit.title,
                'Blob Size': audioBlob.size,
                'State': 'Created',
                'Comments': 'Created successfully',
            })
            addAlert("Visit continued!", "success");
            setUploaded(true);
            await check_visit_status(visit.id, abort_signal.current.signal);
            setGenerating(false);
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            
            if (!cancelRef.current) {
                navigate(`/past-visits/${visit.id}`);
            }
        } catch (error) {
            mixpanel.track('Continue visit Triggered', {
                'Title': visit.title,
                'Blob Size': audioBlob?.size,
                'State': 'Failed',
                'Comments': error.message,
            })
            addAlert("Cannot generate note.", "danger");
            setGenerating(false);
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
            throw new Error(error);
        }
    }

    useEffect(() => {
        setAudioReady(isReady)
    }, [isReady])

    useEffect(() => {
        return () => {
          cleanUpRecording();
          if (wakeLock.current) {
            wakeLock.current.release();
            wakeLock.current = null;
            }
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
            cancelRef.current = true;
            abort_signal.current.abort("Unmounted component");
        };
      }, []); // Include these dependencies

    useEffect(() => {
        if (visit) setNote(visit.generate_notes[0]);
    }, [visit])

    return <Sheet sx={{height: "100%", width: "100%", background: "white", overflowY: "auto"}}>
        <NavBlocker dirty={(generating && !uploaded) || recordingState !== 'inactive'}/>
        <HandleNullAudioModal open={audioDetectionModal} closePopup={() => setAudioDetectionModal(false)} />
        <Box sx={{display: "flex", px: 4, paddingTop: 1, paddingBottom: 1, alignItems: "center", gap: 2, height: { sx: "5%", md: "10%"}}}>
            <Typography level="h3"> Continue Visit </Typography>
            <Chip startDecorator={ <CircleIcon /> } size="sm" color={recording ? "neutral" : generating ? "warning" : audioReady ? "success" : "danger"} sx={{ height: "fit-content", p: 1 }}> 
                {recording ? "Recording..." : generating ? "Capturing..." : audioReady ? "Ready" : "Not Ready"}
            </Chip>
        </Box>
        <Divider />
        <Box sx={{p: 2, backgroundColor: "var(--joy-palette-neutral-50)"}}>
            <Box sx={{display: "flex", p: 2}}>
                <Card sx={{background: "white", width: "100%"}}>
                    <CardContent sx={{ py: 2 }}>
                        {visit && <Typography startDecorator={<User />} level="title-lg"> {visit.title}</Typography>}
                    </CardContent>
                    <Divider sx={{mx: 2}}/>
                    <CardActions sx={{ alignItems: "end", width: "100%", flexDirection: { xs: "column", sm: "row" }, display: "flex"}}>
                        <MicrophoneSelect disabled={recording || generating}/>
                        {recordingState === 'inactive' && (
                            <Button
                                onClick={async () => {
                                await toggleMicrophone();
                                setRecording(true); // Update state to switch button
                                }}
                                startDecorator={<PlayCircleOutlineIcon />}
                                sx={{ display: "flex" }}
                                disabled={generating || !isReady}
                                className="tour-start-capturing"
                            >
                                Start Capturing
                            </Button>
                        )}
                        {recordingState === 'paused' && <Button onClick={async () => { await toggleMicrophone() }} startDecorator={<PlayArrowIcon />} sx={{display: "flex"}} disabled={generating} className="tour-pause-audio"> Unpause </Button>}
                        {recordingState === 'recording' && <Button onClick={async () => { await toggleMicrophone() }} startDecorator={<PauseIcon />} sx={{display: "flex"}} disabled={generating} className="tour-pause-audio"> Pause</Button>}
                        {recordingState !== 'inactive' && <Button onClick={async () => { await continue_note() }} key={(recordingTime < 20 || generating) ? "disabled" : "enabled"} startDecorator={<NoteAddIcon />} sx={{display: "flex", backgroundColor: "var(--dark-blue-button)", '&:hover': {backgroundColor: 'var(--dark-blue-button-hover)', boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.2)" }}} className="tour-complete" disabled={recordingTime < 20 || generating}> Complete </Button>}
                    </CardActions>
                    <Divider sx={{m: 2}}/>
                    <Box sx={{ display: "flex", alignItems:"center", width: "100%" }}>
                        {recordingState === 'recording' && <Box sx={{ width: {xs: '10px', lg: '10px'}, height: {xs: '10px', lg: '10px'}, m: 1, backgroundColor: "red", borderRadius: "50%", alignSelf: "center", animation: "blink 1s infinite"}}/>}
                        {recordingState !== 'recording' && <Box sx={{ width: {xs: '10px', lg: '10px'}, height: {xs: '10px', lg: '10px'}, m: 1, backgroundColor: "grey", borderRadius: "50%", alignSelf: "center" }}/>}
                        {!generating && <TimerComponent seconds={recordingTime} />}
                        {recordingState ==='recording' && !generating && <AudioDisplay audio={audioDeviceRef.current}/>}
                        {recordingState !=='recording' && !generating && <AudioDisplay/>}
                        {generating && <LinearProgress determinate value={progress} sx={{ mx: 2 }} />}
                    </Box>
                    <Typography level="body-xs" sx={{ alignSelf: "center"}}> Make sure to obtain patient consent if you&apos;re using OneChart in an appointment. </Typography>
                </Card>
            </Box>

            <Box sx={{ m: 2, py: 2, border: "1px solid var(--joy-palette-divider)", backgroundColor: "white", borderRadius: "12px" }}>
                {
                    visit ? <>
                        <GenerateNotesListHorizontal generate_notes={visit.generate_notes} handleChange={handleChangeNoteByIndex} handleRegen={() => {}} handleAddNote={() => {}} disabled={true}/>
                    </> : 
                    <CircularProgress />
                }
                {
                    note && note.generated_result && note.generated_result.value ? <Box sx={{ p: 3}}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <NotepadText
                                style={{
                                    color: "var(--main-blue)",
                                    height: "2.75em",
                                    width: "2.75em",
                                    paddingRight: 2,
                                }}
                            />
                            <Typography level="title-lg" sx={{ pb: 0.4 }}>
                                {formatNoteName(note.note_type)}
                            </Typography>
                        </Box>
                        <Divider sx={{my: 2}}/>
                        <PureNoteText noteUnformatted={chooseText(note)}/>
                    </Box> : <Box sx={{ display: "flex", height: "50vh", alignItems: "center", justifyContent: "center", flexDirection: "column", px: 10}}>
                            <Typography level='body-lg'>
                                This note is still processing the context capture, however you can still continue the visit by recoring the session/dictation. Your note will update with the added transcript.
                            </Typography>
                            <CircularProgress size="lg"/>
                        </Box>
                }
            </Box>
        </Box>
    </Sheet>
}

export default ContinueVisitWithAuthCheck(ContinueVisitPage);