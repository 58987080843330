
export class NoteTypes {
    static developmental_consult = "developmental_consult";
    static medical_assessment = "medical_assessment";
    static developmental_followup = "developmental_followup";
    static adhd_letter = "adhd_letter";
    static referrel_letter_short = "referrel_letter_short";
    static one_month_development_assessment = "one_month_development_assessment";
    static two_month_development_assessment = "two_month_development_assessment";
    static four_month_development_assessment = "four_month_development_assessment";
    static six_month_development_assessment = "six_month_development_assessment";
    static nine_month_development_assessment = "nine_month_development_assessment";
    static twelve_month_development_assessment = "twelve_month_development_assessment";
    static eighteen_month_development_assessment = "eighteen_month_development_assessment";
    static four_year_development_assessment = "four_year_development_assessment";
    static autism_diagnostic_letter = "autism_diagnostic_letter";
    static autism_developmental_consult = "autism_developmental_consult";

    static therapy_note = "therapy_note";
    static therapy_case_note = "therapy_case_note";
    static therapy_intake_assessment_adult = "therapy_intake_assessment_adult";
    static therapy_intake_assessment_child = "therapy_intake_assessment_child";
    static therapy_family_remediation = "therapy_family_remediation";
    static therapy_parenting_plan = "therapy_parenting_plan";
    static therapy_marriage_counselling = "therapy_marriage_counselling";
    static therapy_cbt_dbt = "therapy_cbt_dbt";
    static therapy_supervision_clinical_consult = "therapy_supervision_clinical_consult";
    static psychiatric_consult_note = "psychiatric_consult_note"

    static radiology = "radiology";
    static radiology_consult_note = "radiology_consult_note"

    static emergency_response = "emergency_response";

    static pace_rehab_treatment_note = "pace_rehab_treatment_note";

    static sports_medicine_consult_letter = "sports_medicine_consult_letter";
    static sports_clearance = "sports_clearance";
    static sports_medicine = "sports_medicine";

    static physio = "physio";
    static physio_assessment_letter = "physio_assessment_letter";
    static physio_soap_note = "physio_soap_note";
    static chiropractic = "chiropractic";
    static chiro_referral_letter = "chiro_referral_letter";
    static advanced_chiropractic_note = "advanced_chiropractic_note";
    static chiro_sate_note = "chiro_sate_note";
    static chiropractic_soap = "chiropractic_soap";

    static consult_note = "consult_note";
    static consult_note_condensed = "consult_note_condensed";
    static consult_note_multiple_problems = "consult_note_multiple_problems";
    static consult_note_mental_health_examination = "consult_note_mental_health_examination";
    static detailed_soap_note = "detailed_soap_note";
    static discharge_exit_summary = "discharge_exit_summary";
    static patient_handout = "patient_handout";
    static physical_exam_note = "physical_exam_note";
    static prescription_note = "prescription_note";
    static progress_soap_note = "progress_soap_note";
    static patient_summary = "patient_summary";
    static referral_letter = "referral_letter";
    static specialist_note = "specialist_note";

    static athletic = "athletic";
    static at_pt_soap = "at_pt_soap";
    static at_pt_treatment = "at_pt_treatment";
    static at_pt_assessment = "at_pt_assessment";
    static pre_season_ortho_exam = "pre_season_ortho_exam";

    static pedorthist_intake = "pedorthist_intake";
    static orthotic_dispensing = "orthotic_dispensing";

    static geriatric_consult = "geriatric_consult";

    static ptp_initial_consult = "ptp_initial_consult";
    static ptp_ml = "ptp_ml";
    static ptp_progress_note = "ptp_progress_note";
    static soap_therapy = "soap_therapy";
    static cash_fp = "cash_fp";
    static cash_new = "cash_new";
    static rof = "rof";

    static orthodontic_consult_note = "orthodontic_consult_note";
    static orthodontic_progress_note = "orthodontic_progress_note";
    static orthodontic_advanced_note = "orthodontic_advanced_note";
    static general_dental_consult_note = "general_dental_consult_note";
    static dental_examination_note = "dental_examination_note";
    static general_dental_follow_up = "general_dental_follow_up";
    static prosthodontic_consult_note = "prosthodontic_consult_note";
    static prosthodontic_progress_note = "prosthodontic_progress_note";

    static wound_care_note = "wound_care_note";

    static surgical_note = "surgical_note";
    static surgical_note_post_op = "surgical_note_post_op";
    static surgical_note_vein_treatment = "surgical_note_vein_treatment";

    static optometry_consult_note = "optometry_consult_note";
    static optometry_follow_up_note = "optometry_follow_up_note";

    static nursing_home_care_note = "nursing_home_care_note";
    static np_consult = "np_consult";
    static np_soap = "np_soap";
    static nursing_flow_sheet = "nursing_flow_sheet";

    static medical_sick_note = "medical_sick_note";
    static workplace_accomodation_note = "workplace_accomodation_note";

    static podiatry_consult_note = "podiatry_consult_note";

    static cardiology_consult_note = "cardiology_consult_note";

    static general_medical_meeting_staff_notes = "general_medical_meeting_staff_notes"
    
    static orthopedic_consult_note = "orthopedic_consult_note"
    static orthopedic_followup_note = "orthopedic_followup_note"
    static clinic_followup_note = "clinic_followup_note"
    static orthopaedic_surgery_consult_note = "orthopaedic_surgery_consult_note"

    static nephrology_consult = "nephrology_consult"

    static pdf_drug_authorization = "pdf_drug_authorization";
    static pdf_athlete_intake_form = "pdf_athlete_intake_form";
    static cb_exit_form = "cb_exit_form";

    static billing_codes = "billing_codes";

    static test = "test";

    static occupational_therapist_arrow = "occupational_therapist_arrow";

    static clinical_psychology_consult = "clinical_psychology_consult";
    static clinical_psychology_followup = "clinical_psychology_followup";
    static clinical_psychology_advanced = "clinical_psychology_advanced";

    static speech_language_pathology_initial_assessment = "speech_language_pathology_initial_assessment";
    static speech_language_pathology_followup = "speech_language_pathology_followup";
    
    // Personal notes
    static dr_arthur_template = "dr_arthur_template"
    static dr_beth_template = "dr_beth_template"
    static excel_template = "excel_template"
    static paul_template = "paul_template"
    static history_and_physical_note = "history_and_physical_note"
    static mist_therapy_note = "mist_therapy_note"
    static npwt_order = "npwt_order"
    static npwt_procedure_note = "npwt_procedure_note"
    static physician_orders = "physician_orders"
    static wound_care_progress_note = "wound_care_progress_note"

    static rof_azizi = "rof_azizi"
    static ptp_ml_azizi = "ptp_ml_azizi"
    static cash_fp_azizi = "cash_fp_azizi"
    static cash_new_azizi = "cash_new_azizi"
    static soap_therapy_azizi = "soap_therapy_azizi"
    static ptp_initial_consult_azizi = "ptp_initial_consult_azizi"
    static ptp_progress_note_azizi = "ptp_progress_note_azizi"
}

export class PrivateMapping {
  static wish_clinic = [NoteTypes.npwt_order, NoteTypes.npwt_procedure_note, NoteTypes.mist_therapy_note, NoteTypes.wound_care_progress_note, NoteTypes.history_and_physical_note, NoteTypes.physician_orders]
  static hawaii_family = [NoteTypes.dr_arthur_template, NoteTypes.dr_beth_template, NoteTypes.excel_template, NoteTypes.paul_template]
  static dr_azizi_templates = [NoteTypes.cash_fp_azizi, NoteTypes.cash_new_azizi, NoteTypes.ptp_initial_consult_azizi, NoteTypes.ptp_progress_note_azizi, NoteTypes.rof_azizi, NoteTypes.soap_therapy_azizi]

  static private_notes_by_email(email) {
    if (!email || email === "null") {
      return false;
    }
    return Object.keys(PrivateMapping).filter((integrations) => {
      if (email.includes("@medxinsight.com")) {
        return true;
      }
      if (email.includes("@thewishclinic.com") && integrations === "wish_clinic") {
        return true;
      }
  
      if ((email.includes("@hawaiifamilyphysicians.com") || email.includes("@davidarthurs.com")) && integrations === "hawaii_family") {
        return true;
      }

      if (email.includes("@oneandonlychiro.com") && integrations === "dr_azizi_templates") {
        return true
      }
      return false;
    })
  }
}

export class NoteSections {
    static pediatric = [NoteTypes.developmental_consult, NoteTypes.medical_assessment, NoteTypes.developmental_followup, NoteTypes.adhd_letter, NoteTypes.referrel_letter_short, NoteTypes.one_month_development_assessment, NoteTypes.two_month_development_assessment, NoteTypes.four_month_development_assessment, NoteTypes.six_month_development_assessment, NoteTypes.nine_month_development_assessment, NoteTypes.twelve_month_development_assessment, NoteTypes.autism_diagnostic_letter]
    static therapy = [NoteTypes.therapy_note, NoteTypes.therapy_case_note, NoteTypes.therapy_intake_assessment_adult, NoteTypes.therapy_intake_assessment_child, NoteTypes.therapy_family_remediation, NoteTypes.therapy_parenting_plan, NoteTypes.therapy_marriage_counselling, NoteTypes.therapy_cbt_dbt, NoteTypes.therapy_supervision_clinical_consult, NoteTypes.psychiatric_consult_note]
    static radiology = [NoteTypes.radiology, NoteTypes.radiology_consult_note]
    static emergency_response = [NoteTypes.emergency_response]
    static rehab = [NoteTypes.pace_rehab_treatment_note]
    static sports_medicine= [NoteTypes.sports_medicine_consult_letter, NoteTypes.sports_clearance, NoteTypes.sports_medicine]
    static physio = [NoteTypes.physio, NoteTypes.physio_assessment_letter, NoteTypes.physio_soap_note, NoteTypes.chiropractic, NoteTypes.chiro_referral_letter, NoteTypes.advanced_chiropractic_note, NoteTypes.chiro_sate_note, NoteTypes.chiropractic_soap]
    static general_notes = [NoteTypes.consult_note, NoteTypes.consult_note_condensed, NoteTypes.consult_note_multiple_problems, NoteTypes.consult_note_mental_health_examination, NoteTypes.detailed_soap_note, NoteTypes.discharge_exit_summary, NoteTypes.patient_handout, NoteTypes.physical_exam_note, NoteTypes.prescription_note, NoteTypes.progress_soap_note, NoteTypes.patient_summary, NoteTypes.referral_letter, NoteTypes.specialist_note];
    static athletic = [NoteTypes.athletic, NoteTypes.at_pt_soap, NoteTypes.at_pt_treatment, NoteTypes.at_pt_assessment, NoteTypes.pre_season_ortho_exam]
    static pedorthist = [NoteTypes.orthotic_dispensing, NoteTypes.pedorthist_intake]
    static geriatric = [NoteTypes.geriatric_consult]
    static work_comp = [NoteTypes.ptp_initial_consult, NoteTypes.ptp_ml, NoteTypes.ptp_progress_note, NoteTypes.soap_therapy, NoteTypes.cash_fp, NoteTypes.cash_new, NoteTypes.rof]
    static dental_notes = [NoteTypes.orthodontic_consult_note, NoteTypes.orthodontic_progress_note, NoteTypes.orthodontic_advanced_note, NoteTypes.general_dental_consult_note, NoteTypes.dental_examination_note, NoteTypes.general_dental_follow_up, NoteTypes.prosthodontic_consult_note, NoteTypes.prosthodontic_progress_note]
    static wound_care = [NoteTypes.wound_care_note]
    static surgical_notes = [NoteTypes.surgical_note, NoteTypes.surgical_note_post_op, NoteTypes.surgical_note_vein_treatment]
    static optometry = [NoteTypes.optometry_consult_note, NoteTypes.optometry_follow_up_note]
    static nursing = [NoteTypes.nursing_home_care_note, NoteTypes.np_consult, NoteTypes.np_soap, NoteTypes.nursing_flow_sheet]
    static external_letters = [NoteTypes.medical_sick_note, NoteTypes.workplace_accomodation_note]
    static podiatry = [NoteTypes.podiatry_consult_note]
    static cardiology = [NoteTypes.cardiology_consult_note]
    static office_and_admin = [NoteTypes.general_medical_meeting_staff_notes]
    static orthopedic = [NoteTypes.orthopedic_consult_note, NoteTypes.orthopedic_followup_note, NoteTypes.clinic_followup_note, NoteTypes.orthopaedic_surgery_consult_note]
    static nephrology = [NoteTypes.nephrology_consult]
    static pdf_forms = [NoteTypes.pdf_drug_authorization, NoteTypes.pdf_athlete_intake_form, NoteTypes.cb_exit_form]
    static beta = [NoteTypes.billing_codes]
    static test = [NoteTypes.test]
    static occupational_therapy = [NoteTypes.occupational_therapist_arrow]
    static clinical_psychology = [NoteTypes.clinical_psychology_consult, NoteTypes.clinical_psychology_followup, NoteTypes.clinical_psychology_advanced]
    static speech_language_pathology = [NoteTypes.speech_language_pathology_initial_assessment, NoteTypes.speech_language_pathology_followup]

    static using_note_type(noteType) {
        // Iterate over the class's static properties
        return Object.entries(NoteSections)
            .filter(([/* unused */, noteTypes]) => Array.isArray(noteTypes) && noteTypes.includes(noteType))
            .map(([sectionName]) => sectionName); // Return the section names
    }

    // Show em all
    static show_all_note_sections_no_pdf_no_beta() {
        return Object.keys(NoteSections).filter(
          (sectionName) => sectionName !== "beta"
        );
      }
}