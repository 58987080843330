import React, { useRef, useEffect, useState } from 'react';
import { useRecorder } from '../../../context/MicContext';

const AudioWaveform = ({ audioData }) => {
  const canvasRef = useRef(null);

  const draw = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    const context = canvas.getContext('2d');
    const height = canvas.height;
    const width = canvas.width;
    
    context.imageSmoothingEnabled = false;
    context.clearRect(0, 0, width, height);
    
    const sliceWidth = width / audioData.length;
    let x = 0;
    
    context.lineWidth = 1;
    context.strokeStyle = '#5774f0';
    context.beginPath();
    context.moveTo(0, height / 2);
    
    for (const item of audioData) {
      const y = (item / 255.0) * height;
      context.lineTo(x, y);
      x += sliceWidth;
    }
    
    context.lineTo(width, height / 2);
    context.stroke();
  };
  // Canvas setup and resize logic using useEffect
  useEffect(() => {
    const updateCanvasDimensions = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;
      
      canvas.width = canvas.parentElement.clientWidth;
      canvas.height = Math.max(125, canvas.parentElement.clientHeight / 2);
      draw();
    };
    
    window.addEventListener('resize', updateCanvasDimensions);
    updateCanvasDimensions();
    
    return () => window.removeEventListener('resize', updateCanvasDimensions);
  }, []);
  
  // Draw function that uses the audioData
  useEffect(() => {
    draw();
  }, [audioData]);
  
  return (
    <div style={{ width: '96%', height: '100%', position: 'relative', marginLeft: '2%', marginRight: '2%' }}>
      <canvas ref={canvasRef} style={{ width: '100%', height: 'auto' }} />
    </div>
  );
};

const AudioAnalyser = ({ raiseAudioError }) => {
  const { audioContextRef, recordingState, audioDeviceRef } = useRecorder();
  const [audioData, setAudioData] = useState(new Uint8Array(0));
  const analyserRef = useRef(null);
  const sourceRef = useRef(null);
  const rafIdRef = useRef(null);
  const dataArrayRef = useRef(null);

  useEffect(() => {
    if (!audioContextRef.current || recordingState !== "recording") return;
    const analyser = audioContextRef.current.createAnalyser();
    analyserRef.current = analyser;
    dataArrayRef.current = new Uint8Array(analyser.frequencyBinCount);
  
    return () => {
      analyser.disconnect();
    };
  }, [recordingState]);
  
  useEffect(() => {
    if (recordingState !== "recording" || !audioDeviceRef.current || !analyserRef.current) return;
  
    const audioCtx = audioContextRef.current;
    audioCtx.resume();
  
    const source = audioCtx.createMediaStreamSource(audioDeviceRef.current);
    sourceRef.current = source;
    source.connect(analyserRef.current);
  
    const tick = () => {
      analyserRef.current.getByteTimeDomainData(dataArrayRef.current);
      setAudioData([...dataArrayRef.current]);
      setTimeout(() => {
        rafIdRef.current = requestAnimationFrame(tick);
      }, 50);
    };
  
    rafIdRef.current = requestAnimationFrame(tick);
  
    return () => {
      cancelAnimationFrame(rafIdRef.current);
      source.disconnect();
    };
  }, [recordingState, audioDeviceRef]);

  useEffect(() => {
    if (recordingState !== "recording") return;
  
    const interval = setInterval(() => {
      const buffer = dataArrayRef.current;
      if (!buffer || buffer.length === 0) return;
  
      const isSilent = buffer.every((val) => val === 128);
  
      if (isSilent) {
        raiseAudioError();
        console.warn("Mic appears to be silent!");
      }
    }, 1000); // Check every 1 second
  
    return () => clearInterval(interval);
  }, [recordingState]);
  
  return <AudioWaveform audioData={audioData} />;
};

export default AudioAnalyser;