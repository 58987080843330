import React, { useState, createContext, useCallback, useMemo } from "react";
import { Alert, IconButton, Snackbar } from "@mui/joy";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { CircleAlert } from "lucide-react";
import PropTypes from "prop-types";

// Create the AlertContext
export const AlertContext = createContext();

const AlertProviderComponent = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  // Memoized function to add alerts
  const addAlert = useCallback((message, severity) => {
    setAlerts((prevAlerts) => {
      if (prevAlerts.length >= 10) {
        prevAlerts = prevAlerts.slice(1); // Remove the oldest alert if max reached
      }
      return [...prevAlerts, { message, severity, id: Date.now() }];
    });

    // Automatically remove alert after 5 seconds
    setTimeout(() => {
      setAlerts((prevAlerts) => prevAlerts.slice(1)); // Always remove oldest alert
    }, 5000);
  }, []);

  // Memoized function to remove alerts
  const removeAlert = useCallback(() => {
    setAlerts((prevAlerts) => prevAlerts.slice(1)); // Always remove oldest alert
  }, []);

  // Memoized context value
  const contextValue = useMemo(() => ({ addAlert, alerts }), [addAlert, alerts]);

  // Function to handle icons
  const handleIcon = (severity) => {
    if (severity === "danger") return <ErrorOutlineIcon fontSize="xl" />;
    if (severity === "success") return <CheckIcon fontSize="xl" />;
    if (severity === "warning") return <CircleAlert width="30px" />;
  };

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
      <Snackbar
        open={alerts.length > 0}
        onClose={(event, reason) => {
          if (reason === "clickaway") return;
          removeAlert();
        }}
        variant="plain"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          padding: "0px",
          minWidth: "0px",
          display: "flex",
          background: "transparent",
          flexDirection: "column",
          boxShadow: "unset",
          alignItems: "center",
        }}
      >
        {alerts.map((alert) => (
          <Alert
            key={alert.id}
            startDecorator={handleIcon(alert.severity)}
            color={alert.severity}
            endDecorator={
              <IconButton onClick={removeAlert}>
                <ClearIcon fontSize="md" />
              </IconButton>
            }
            variant="outlined"
            sx={{ boxShadow: "var(--joy-shadow-md)" }}
          >
            {alert.message}
          </Alert>
        ))}
      </Snackbar>
    </AlertContext.Provider>
  );
};

// Memoize the provider itself
export const AlertProvider = React.memo(AlertProviderComponent);

AlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
