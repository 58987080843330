import React, { useEffect, useRef } from "react";
import { Modal, Sheet, ModalClose, Typography } from "@mui/joy";
import PropTypes from "prop-types";

function HandleNullAudioModal({open, closePopup}) {
    const audio_ref = useRef();

    const play_sound = () => {
        if (audio_ref.current && open) {
            audio_ref.current.play();
        }
    }

    useEffect(() => {
        play_sound();
    }, [open])

    return (<>
    <audio ref={audio_ref} src="/assets/alert.mp3"></audio>
    <Modal aria-labelledby="modal-title"
    aria-describedby="modal-desc" open={open} onClose={closePopup} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Sheet variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            display: "flex",
            flexDirection: "column"
          }}>
            <ModalClose variant="plain" sx={{ m: 1, width: "fit-content" }} />
            <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
            >
                Audio not detected!
            </Typography>
            <Typography id="modal-desc" textColor="text.tertiary" paddingBottom='10px'>
                Please be aware that your microphone is broken, not plugged in, or your browser has given priority to another site. Please swap to a different microphone and continue your recording!
            </Typography>
        </Sheet>
    </Modal>
    </>)
}

HandleNullAudioModal.propTypes = {
    open: PropTypes.bool.isRequired,
    closePopup: PropTypes.func.isRequired,
}

export default HandleNullAudioModal;


