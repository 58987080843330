import React from "react";
import { Modal, Sheet, ModalClose, Typography, Button } from "@mui/joy";
import PropTypes from "prop-types";

function ConfirmDeleteNoteModal({ on_close, open, delete_note }) {

    const handleDelete = () => {
        delete_note();
        on_close();
    }

    return <Modal aria-labelledby="modal-title"
        aria-describedby="modal-desc" open={open} onClose={on_close} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Sheet variant="outlined"
              sx={{
                maxWidth: 500,
                borderRadius: 'md',
                p: 3,
                boxShadow: 'lg',
                display: "flex",
                flexDirection: "column"
              }}>
                <ModalClose variant="plain" sx={{ m: 1, width: "fit-content" }} />
                <Typography
                    component="h2"
                    id="modal-title"
                    level="h4"
                    textColor="inherit"
                    fontWeight="lg"
                    mb={1}
                >
                    Confirm Delete Note
                </Typography>
                <Typography id="modal-desc" textColor="text.tertiary" paddingBottom='10px'>
                    This will delete the note! Are you sure you want to continue?
                </Typography>
                <Button onClick={handleDelete}> CONFIRM </Button>
            </Sheet>
        </Modal>
}

ConfirmDeleteNoteModal.propTypes = {
    on_close: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    delete_note: PropTypes.func.isRequired,
}

export default ConfirmDeleteNoteModal;