import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";

mixpanel.init('a66909f81ce1983b076c893a4d6143f3', {
  debug: false,           // Disable debug logging in production
  track_pageview: false,   // Keep this if you want to track page views
  persistence: "localStorage", // Keep if needed; otherwise, use 'cookie' for shorter sessions
  ignore_dnt: false,      // Respect Do Not Track (DNT) settings for compliance
  disable_persistence: false, // Ensure data is stored
});


Sentry.init({
  dsn: "https://6d05dbdde9a9861d18b42167d812bfe3@o4508684464619520.ingest.us.sentry.io/4508684466061312",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
      isNameRequired: true,
      isEmailRequired: true,
    }),
  ],
  environment: process.env.NODE_ENV,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
