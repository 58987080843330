// Not actually the main layout lmao, it's the navbar
import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Sheet, List, ListItem, ListItemButton, ListItemDecorator, ListItemContent, AspectRatio, Option, CircularProgress, Stack, Typography, Divider, Button, Box, Avatar, Select, IconButton } from '@mui/joy';
import MicIcon from '@mui/icons-material/Mic';
import HistoryIcon from '@mui/icons-material/History';
import HelpComponent from './HelpElement';
import { LogOut } from 'lucide-react';

import '../styling/NavigationBar.css';
import { convertTimeString } from '../utils/TimeConversion';
import { grabAvatar } from '../utils/FormatNoteNameAndText';
import { useDispatch, useSelector } from 'react-redux';
import { get_visit_generate_by_id } from '../redux/reducers/visitReducer';

import { motion, AnimatePresence } from "framer-motion";
import { useQuery } from '@tanstack/react-query';
import { get_user_account } from '../services/UserAccount';

function MainLayout() {
  const location = useLocation();
  const { data, isLoading, error } = useQuery({
    queryKey: ['user'],
    queryFn: get_user_account,
  })
  const { generating_visits, state } = useSelector((state) => state.visits);
  const dispatch = useDispatch();
  const [ visits, setVisits ] = useState([]);

  const check_interval_ref = useRef();
  const generating_vists_ref = useRef(generating_visits);

  const isActive = (path) => {
    return location.pathname === path;
  }

  const setup_interval_checker = () => {
    check_interval_ref.current = setInterval(() => {
      // Always get the latest value from the ref:
      for (let visit of generating_vists_ref.current) {
        console.log("Dispatching visit:", visit.id);
        dispatch(get_visit_generate_by_id(visit.id));
      }
    }, 10000);
  };


  useEffect(() => {
    if (state === "empty") {
      if (check_interval_ref.current) {
        console.log("Clearing interval");
        clearInterval(check_interval_ref.current);
        check_interval_ref.current = null;
      }
    } else if (state === "needs_check") {
      if (!check_interval_ref.current) {
        setup_interval_checker();
      }
    }
    // Cleanup the interval on unmount:
    return () => {
      if (check_interval_ref.current) {
        clearInterval(check_interval_ref.current);
      }
    };
  }, [state]);

  useEffect(() => {
    setVisits(generating_visits);
    generating_vists_ref.current = generating_visits;
  }, [generating_visits])

  return (
    <div id="nav">
      <Sheet sx={{ display: { xs:"none", sm:"none", md:"flex"}, flexDirection: "column", flexGrow: 1, borderRight: "1px solid var(--joy-palette-divider)", width: "300px", overflowY: "scroll"}}>
        <AspectRatio objectFit='contain' ratio="8/3" sx={{ padding: '5px', paddingLeft: "10px", paddingRight: "10px", backgroundColor:'white' }} variant='plain'>
          <img src="/assets/backgroundlogo.png" alt='Logo'/>
        </AspectRatio>
        <List component="nav" sx={{ backgroundColor: 'white', padding: '10px' }}>
          <ListItem>
            <ListItemButton component={NavLink} to="/new-visit" sx={{ backgroundColor: isActive('/new-visit') ? '#f1f3f4' : 'var(--main-blue)' }}>
              <ListItemDecorator sx={{ color: "white" }}> <MicIcon color={isActive('/new-visit') ? '600' : 'white'} fontSize='xl3'/></ListItemDecorator>
              <ListItemContent sx={{ fontSize: '13pt', fontWeight: isActive('/new-visit') ? '600' : 'inherit', color: isActive('/new-visit') ? "inherit" : "white" }}> New Visit </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton component={NavLink} to="/past-visits" sx={{ backgroundColor: isActive('/past-visits') ? '#f1f3f4' : 'inherit' }}>
              <ListItemDecorator> <HistoryIcon color={isActive('/past-visits') ? '600' : 'inherit'} fontSize='xl3'/></ListItemDecorator>
              <ListItemContent sx={{ fontSize: '13pt', fontWeight: isActive('/past-visits') ? '600' : 'inherit' }}> Past Visits </ListItemContent>
            </ListItemButton>
          </ListItem>
          {data && !isLoading && <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              component={NavLink}
              to={"/account"}
              variant="plain"
              sx={{ 
                display: "flex", 
                justifyContent: "start",
                justifyItems: "left",
                backgroundColor: "white", 
                width: "100%",
                transition: "gap 0.5s ease, padding-left 0.5s ease",
                gap: 1,
                "&:hover": {
                  gap: 3,
                  pl: 2,
                }, 
              }}>
              <Avatar>
                {grabAvatar(data.full_name)}
              </Avatar>
              <Box>
                <Typography level="title-sm"> {data.full_name} </Typography>
                <Typography level="body-xs"> {data.email.slice(0, 30)} </Typography>
              </Box>
            </Button>
          </Box>
          }
          {isLoading && <CircularProgress />}
          <Stack sx={{ px: "1em" }}>
              <Typography level="body-xs">
                Visits loading from this session:
              </Typography>
              <Divider sx={{ width: "110%", alignSelf: "center"}}/>
              {visits && (
              <AnimatePresence>
                {visits.map((item) => (
                  <motion.div
                    key={item.id} // Ensure a unique key
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, x: -20 }} // Exit animation when removed
                    transition={{ duration: 0.3 }}
                  >
                    <Box sx={{ px: 2 }}>
                      <Box sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "space-between"}}>
                        <Box sx={{ justifyItems: "left" }}>
                          <Typography level="body-sm">{item['title']}</Typography>
                          <Typography level="body-xs">{convertTimeString(item['created_at'])}</Typography>
                        </Box>
                        <div className="loading-container">
                          <div className="dot"></div>
                          <div className="dot"></div>
                          <div className="dot"></div>
                        </div>
                      </Box>
                    </Box>
                  </motion.div>
                ))}
              </AnimatePresence>
            )}
          </Stack>
        </List>
        <HelpComponent/>
      </Sheet>
    </div>
  );
}

export default MainLayout;