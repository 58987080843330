import React, { useState, createRef, useContext, forwardRef, useEffect, useRef } from "react";
import { createNoteText, formatNoteName } from "../../utils/FormatNoteNameAndText";
import { NoteSections } from "../../utils/constants/Notes";
import { getFullName } from "../../services/Authentication";
import { formattedText } from "./DisplayText";
import { AlertContext } from "../../context/AlertFlag";
import { downloadTextoTxt, NoteToPDF } from "../../utils/DownloadNote";
import { Box, Typography, Button, Select, Option, Divider, IconButton, CircularProgress} from "@mui/joy";
import { NotepadText, Share, X, Check, Pencil, Trash  } from "lucide-react";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { copyToClipboard } from "../../utils/Clipboard";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { DocEmbedder } from "../embed-pdf/buildpdf";
import ConfirmDeleteNoteModal from "../modals/ConfirmDeleteGenNote";
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { get_generate_note } from "../../services/GenerateNote";


// Copy full text, save text
export const NoteTextComponent = forwardRef(function NoteTextComponent(
    { visit, note, lift_editing=()=>{}, lift_save=()=>{}, lift_delete=()=>{}, can_edit=true },
    ref
) {
    const { addAlert } = useContext(AlertContext);
    const [editingNote, setEditingNoteFlag] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    const queryClient = useQueryClient();
    const mutation_check_note = useMutation({
        mutationFn: get_generate_note,
        onSuccess: (data) => {
            if ("value" in data.generated_result) {
                queryClient.invalidateQueries({ queryKey: ['visit', visit.id] })
                if (checkNoteIntervalRef.current) {
                    clearInterval(checkNoteIntervalRef.current);
                    checkNoteIntervalRef.current = null;
                }
            }
        },
        onError: (error) => {
            throw new Error(error);
        }
    })

    const checkNoteIntervalRef = useRef(null);

    let titles = [];
    let noteParts = [];
    let noteBodyReferences = [];


    const copyNote = () => {
        copyToClipboard(createNoteText(titles, noteBodyReferences));
        addAlert("Copied note!", "success");
    }

    const chooseText = () => {
        try {
            if (!note.user_edited_result.value || note.user_edited_result.value.length <= 0) {
                return note.generated_result.value;
            } else {
                return note.user_edited_result.value;
            }
        } catch (error) {
            addAlert("Something went wrong", "danger");
        }
    };

    const setEditingNote = (value) => {
        setEditingNoteFlag(value);
        lift_editing(value);
    };

    const handleCancelSaveNote = () => {
        for (let index = 0; index < noteBodyReferences.length; index++) {
            if (noteBodyReferences[index].current) {
                noteBodyReferences[index].current.textContent = noteParts[index];
            }
        }
    };

    const handleSaveNote = async () => {
        await lift_save(createNoteText(titles, noteBodyReferences), note.id);
    };

    const handleDeleteNote = async () => {
        await lift_delete(note.id)
    }

    const triggerExport = (setting) => {
        try {
            switch (setting) {
                case "txt":
                    downloadTextoTxt(chooseText(), visit.title, note.created_at);
                    break;
                case "pdf":
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const setRefs = (index) => {
        noteBodyReferences[index] = createRef();
    };

    useEffect(() => {
        if (!note.generated_result || !("value" in note.generated_result)) {
            checkNoteIntervalRef.current = setInterval(() => {
                mutation_check_note.mutate({ visit_id: visit.id, note_id: note.id })
            }, 2000)
        }

        return () => {
            if (checkNoteIntervalRef.current) {
                clearInterval(checkNoteIntervalRef.current)
            }
        }
    }, [note])

    return (
        <>
            {visit.generate_notes.length > 0 && NoteSections.pdf_forms.includes(note.note_type) ? 
            <Box sx={{ px: 2 }}>
                <DocEmbedder note={note} />
            </Box>
            :
            <>
                {note && note.generated_result && "value" in note.generated_result ? <Box ref={ref}>
                    {openConfirmDelete && <ConfirmDeleteNoteModal open={openConfirmDelete} on_close={() => { setOpenConfirmDelete(false) }} delete_note={handleDeleteNote}/>}
                    <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", px: 2 }}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <NotepadText
                                style={{
                                    color: "var(--main-blue)",
                                    height: "2.75em",
                                    width: "2.75em",
                                    paddingRight: 2,
                                }}
                            />
                            <Typography level="title-lg" sx={{ pb: 0.4 }}>
                                {formatNoteName(note.note_type)}
                            </Typography>
                        </Box>
                        {can_edit && <Box sx={{ display: "flex", gap: 2 }}>
                            <Button sx={{ display: "flex", whiteSpace: "nowrap", p: 1, py: 0.2, backgroundColor: "var(--main-blue)" }} startDecorator={<CopyAllIcon />} onClick={() => copyNote()}>
                                Copy Note
                            </Button>
                            {editingNote && (
                                <Button
                                    startDecorator={<X />}
                                    sx={{ backgroundColor: "white", color: "gray", display: "flex" }}
                                    variant="plain"
                                    onClick={() => {
                                        handleCancelSaveNote();
                                        setEditingNote(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            )}
                            <Button
                                sx={{ backgroundColor: "white", display: "flex" }}
                                variant="plain"
                                startDecorator={editingNote ? <Check /> : <Pencil />}
                                onClick={async () => {
                                    if (editingNote) {
                                        await handleSaveNote();
                                        setEditingNote(false);
                                    } else {
                                        setEditingNote(true);
                                    }
                                }}
                            >
                                {editingNote ? "Save" : "Edit"}
                            </Button>
                            <Select
                                value="export"
                                variant="plain"
                                sx={{ minWidth: 95, py: 2, color: "var(--dark-blue-button)" }}
                                onChange={(event, value) => {
                                    triggerExport(value);
                                }}
                                indicator={null}
                                startDecorator={<Share style={{ height: "20px", width: "20px" }} />}
                            >
                                <Option value="export" disabled sx={{ display: "none" }}>
                                    Export
                                </Option>
                                <Option value="txt">to TXT file</Option>
                                <Option value="pdf">
                                    <PDFDownloadLink
                                        document={
                                            <NoteToPDF
                                                note={note}
                                                createdBy={getFullName()}
                                                noteType={note.note_type}
                                            />
                                        }
                                        fileName={visit.title}
                                    >
                                        to PDF file
                                    </PDFDownloadLink>
                                </Option>
                            </Select>
                            <IconButton color="danger" onClick={() => { setOpenConfirmDelete(true) }}>
                                <Trash style={{ width: "20px" }}/>
                            </IconButton>
                        </Box>}
                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ whiteSpace: "pre-wrap", py: 1, px: 3 }}>
                        {formattedText(
                            chooseText(),
                            (new_titles) => {
                                titles = new_titles;
                            },
                            setRefs,
                            noteBodyReferences,
                            editingNote,
                            (parts) => {
                                noteParts = parts;
                            }
                        )}
                    </Box>
                </Box> : 
                <Box sx={{ display: "flex", height: "50vh", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                    <Typography level='body-lg'>
                        Capturing visit context... Please refresh the page to check the updated status!
                    </Typography>
                    <CircularProgress size="lg"/>
                </Box>
                }
            </>
            }
        </>
    );
});

// Conditionally rendered depending on state of generated_result, so can expect value
NoteTextComponent.propTypes = ({
    note: PropTypes.shape({
        id: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired,
        note_type: PropTypes.string.isRequired,
        user_edited_result: PropTypes.shape({ value: PropTypes.string }),
        generated_result: PropTypes.shape({ value: PropTypes.string }),
    }),
    visit: PropTypes.shape({
        title: PropTypes.string.isRequired,
        generate_notes: PropTypes.arrayOf(PropTypes.object).isRequired,
    }),
    lift_editing: PropTypes.func.isRequired,
    lift_save: PropTypes.func.isRequired,
    lift_delete: PropTypes.func.isRequired,
})