import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { check_continue_visit } from "../../services/GenerateNote";
import { CircularProgress, Sheet } from "@mui/joy";

function ContinueVisitWithAuthCheck(WrappedComponent) {
    // Create and return a React function component (capitalized)
    const WithAuthCheck = (props) => {
      const [canContinue, setCanContinue] = useState(null);
      const navigate = useNavigate();
      const { visitId } = useParams();
  
      useEffect(() => {
        const check_continue = async () => {
          try {
            const response = await check_continue_visit(visitId);
            // Validate response
            if (response.data["can_continue_within_limit"] && response.data["has_existing_transcript"]) {
              setCanContinue(true);
            } else {
              let message = "Visit cannot be continued. ";
              if (!response.data["can_continue_within_limit"]) {
                message = message + "Only visits created in the last 24 hours can be continued. ";
              }
  
              if (!response.data["has_existing_transcript"]) {
                message = message + "Transcript doesn't exist. ";
              }
  
              navigate("/restricted", {
                state: {
                  message: message,
                  redirectURL: `/past-visits/${visitId}`,
                },
                replace: true,
              });
            }
          } catch (e) {
            throw new Error(e);
          }
        };
  
        check_continue();
      }, []);
  
      if (canContinue === null) return <Sheet sx={{ height: "80vh", display: "flex", alignItems: "center", justifyContent: "center"}} ><CircularProgress /></Sheet>;
      return <WrappedComponent {...props} />;
    };
  
    // Return the component with a meaningful display name for debugging
    WithAuthCheck.displayName = `ContinueVisitWithAuthCheck(${
      WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`;
    
    return WithAuthCheck;
  }
  



export default ContinueVisitWithAuthCheck;