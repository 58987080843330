import React from 'react';
import PropTypes from "prop-types";


function TimerComponent({ seconds }) {
  const formatTime = (secondsElapsed) => {
    const hours = Math.floor(secondsElapsed / 3600);
    const minutes = Math.floor((secondsElapsed % 3600) / 60);
    const seconds = secondsElapsed % 60;

    return [hours, minutes, seconds]
    .map(val => val < 10 ? `0${val}` : val)
    .join(':');
  }

  return <> {formatTime(seconds)} </>
}

export default TimerComponent;