import React, { useEffect, useState } from "react";
import { pureTitleAndParts } from "./DisplayText";
import { Box, Typography } from "@mui/joy";
import { capitalizeWords } from "../../utils/FormatNoteNameAndText";


export function PureNoteText({ noteUnformatted }) {
    const [titles, setTitles] = useState([]);
    const [parts, setParts] = useState([]);


    useEffect(() => {
        const parseResult = pureTitleAndParts(noteUnformatted);
        setTitles(parseResult["titles"])
        setParts(parseResult["parts"])
    }, [noteUnformatted])

    return <>
        {(titles.map((title, index) => {
            return <Box key={`${title} ${index}`}>
                <Box sx={{mb: '4px', display: "flex"}}>
                    <Typography level="body-xl" sx={{alignSelf: "center", m: 0, fontSize: '1.125rem'}}>
                        {capitalizeWords(title.toLowerCase(), ' ')}
                    </Typography>
                </Box>
                <Typography level="body-sm" sx={{ whiteSpace: "pre-line" }}>
                    {`${parts[index]} \n\n`}
                </Typography>
            </Box>
        }))
        }
    </>

}