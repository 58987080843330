import React from "react";
import { grabLocalTime } from "../../utils/TimeConversion";
import { ListItem, ListItemButton, Box, Typography, LinearProgress } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { Timer } from "lucide-react";
import { NoteNameChip } from "./NoteNameChip";
import PropTypes from "prop-types";

function PastVisitCard({ visit }) {
    const navigate = useNavigate();

    const parseTitle = (title) => {
        if (title.length >= 30) {
            return `${title.slice(0, 30)}...`
        } else {
            return title
        }
    }

    const openSelectedVisit = async () => {
      navigate(`${visit['id']}`);
    }

    return <>
            <ListItem key={visit['id']} sx={{padding: '0px', width: '100%'}}>
                <ListItemButton {...(window.location.href.includes(visit['id']) && {
                    selected: true,
                    color: 'neutral',
                })}
                sx={{
                    p: 2, 
                    display: "flex",
                    borderRadius: '0px', 
                    marginTop: '0px', 
                    marginBottom: '0px',
                    transition: "padding-left 0.5s ease",
                    "&:hover": {
                        pl: 6,
                    }, 
                 }} onClick={openSelectedVisit} disabled={visit['generate_notes'].length < 1}>
                    <Box sx={{ pl: 1, width: '100%', backgroundColor: "inherit"}}>
                        <Typography level="title-md" sx={{ mb: 0.5 }}>
                            {parseTitle(visit['title'])}
                        </Typography>
                        <Box
                            sx={{
                            display: 'flex',
                            alignItems: "center",
                            gap: 0.5,
                            mb: 0.5,
                            }}
                        >
                            <Timer style={{ width: "15px" }}/>
                            <Typography level="body-xs" textColor="text.tertiary">
                            {grabLocalTime(visit['created_at'])}
                            </Typography>
                        </Box>
                        {visit['generate_notes'].length > 0 ? 
                            <> 
                                {visit['generate_notes'].map((item) => {
                                    return <NoteNameChip key={visit.generate_notes.id} note={item} sx={{ borderRadius: "4px", mr: 1, mb: 1}}/>
                                })}
                            </> 
                            : 
                            <LinearProgress variant='indeterminate' sx={{width:'75%'}}/>
                        }
                    </Box>
                </ListItemButton>
            </ListItem>
        </>
}

PastVisitCard.propTypes = ({
    visit: PropTypes.shape({
        id: PropTypes.string.isRequired,
        generate_notes: PropTypes.arrayOf(PropTypes.oneOfType([ PropTypes.object, PropTypes.oneOf([null])])).isRequired,
        title: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired,
    })
})

export default PastVisitCard