import React from "react"
import { Tooltip, Box, Typography } from "@mui/joy"
import { Info } from "lucide-react"


export class ConversationLanguage {
    static ar = "ar"
    static nl = "nl"
    static zh_cn = "zh-CN"
    static zh_tw = "zh-TW"
    static zh_hk = "zh-HK"
    static en = "en"
    static en_us = "en-US"
    static fr = "fr"
    static fr_ca = "fr-CA"
    static de = "de"
    static ja = "ja"
    static ko = "ko"
    static multi = "multi"
    static pl = "pl"
    static ru = "ru"
    static es = "es"
}

export class NoteLanguage {
    static ar = "ar"
    static nl = "nl"
    static zh_cn = "zh-CN"
    static zh_tw = "zh-TW"
    static zh_hk = "zh-HK"
    static en = "en"
    static en_us = "en-US"
    static fr = "fr"
    static fr_ca = "fr-CA"
    static de = "de"
    static ja = "ja"
    static ko = "ko"
    static pl = "pl"
    static ru = "ru"
    static es = "es"
}

export function mapLanguageNames(language) {
    switch(language) {
        case ("ar"): return "Arabic"
        case ("nl"): return "Dutch"
        case ("zh-CN"): return "Chinese(Mandarin, Simplified)"
        case ("zh-TW"): return "Chinese(Mandarin, Traditional)"
        case ("zh-HK"): return "Chinese(Cantonese, Simplified)"
        case ("en"): return "English"
        case ("en-US"): return "English(USA)"
        case ("fr"): return "French"
        case ("fr-CA"): return "French(Québecois)"
        case("de"): return "German"
        case("ja"): return "Japanese"
        case("ko"): return "Korean"
        case("multi"): return <Tooltip title="Note that Multilanguage only supports Spanish and English." size="sm">
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexGrow: "1"}}>
                <Typography> Multilanguage </Typography>
                <Info />
            </Box>
        </Tooltip>
        case("pl"): return "Polish"
        case ("ru"): return "Russian"
        case("es"): return "Spanish"
    }
}