import React from "react";
import { formatNoteName, NoteNameIcon } from "../../utils/FormatNoteNameAndText";
import { Chip, CircularProgress, Typography } from "@mui/joy";
import PropTypes from "prop-types";



export function NoteNameChip({ note, ...rest }) {
    return <Chip size="sm" variant="soft" startDecorator={note.model_version && note.source && note.model_version.toLowerCase()  !== "pending" && note.source.toLowerCase() !== "pending" ? <NoteNameIcon note_type={note.note_type}/> : <CircularProgress size="sm"/> } {...rest} >
        <Typography level="body-xs">
            {formatNoteName(note.note_type)}
        </Typography>
    </Chip>
}

NoteNameChip.propTypes = {
    note: PropTypes.shape({
        model_version: PropTypes.string.isRequired,
        source: PropTypes.string.isRequired,
        note_type: PropTypes.string.isRequired,
    })
}