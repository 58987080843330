import React, { useState, useEffect, useContext, useRef } from "react";
import PastVisitCard from "../components/custom/PastVisitCard";
import { AlertContext } from "../context/AlertFlag";
import {
  Box,
  CircularProgress,
  List,
  listItemButtonClasses,
  Sheet,
  Typography,
  Input,
  IconButton,
  Chip,
  Divider,
} from "@mui/joy";
import Pagination from "../components/custom/Pagination";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Filter } from "lucide-react";
import FilterPopover from "../components/modals/FilterModal";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { read_visits } from "../services/VisitRouter";

const REFRESH_INTERVAL = 20000;

const dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

function PastVisits() {
  const { addAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const location = useLocation();

  
  const [searchString, setSearchString] = useState(
    new URLSearchParams(location.search).get("title") || null
  );

  const [filterOpen, setFilterOpen] = useState(false);
  const [fromTime, setFromTime] = useState(() => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    return currentDate;
  })
  const [toTime, setToTime] = useState(new Date());
  const [page, setPage] = useState(1);
  const [collapsed, setCollapsed] = useState(false);
  const debounceSearch = useRef(null);

  const { data: visits, isLoading: visitsLoading, error: visitsError } = useQuery({
    queryKey: ['visits', fromTime, toTime, page, searchString],
    queryFn: () => read_visits(fromTime.toISOString(), toTime.toISOString(), page, searchString),
    refetchInterval: REFRESH_INTERVAL,
  });

  const dateHeader = useRef(null);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handleSearch = async (event) => {
    if (debounceSearch.current) {
      clearTimeout(debounceSearch.current);
    }

    debounceSearch.current = setTimeout(() => {
      const value = event.target.value;
      setPage(1);
      setSearchString(value);
      
      const queryParams = new URLSearchParams(location.search);
      if (value) {
        queryParams.set("title", value);
      } else {
        queryParams.delete("title");
      }
      navigate({ search: queryParams.toString() });
    }, 700)
  };

  const setDateFilter = (fromTime, toTime) => {
    setPage(1);
    setFromTime(fromTime);
    setToTime(toTime);
    addAlert("Applied!", "success");
  }

  useEffect(() => {
    if (visitsError) {
      addAlert(visitsError.message, "danger");
    }
  }, [visitsError, addAlert]);

  return (
    <Sheet sx={{ overflow: "hidden" }}>
      <Box sx={{ display: "flex" }}>
        {/* LEFT-SIDE (Search + List) */}
        <FilterPopover open={filterOpen} setClose={() => {setFilterOpen(false)}} raise_selections={setDateFilter}/>
        <Box
          sx={{
            height: "100vh",
            overflowY: "auto",
            overflowX: "hidden",
            borderRight: "1px solid var(--joy-palette-divider)",
            minWidth: collapsed ? "0px" : "300px",
            width: collapsed ? "0px" : "25%",
            transition: "width 0.4s ease",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            visibility: collapsed ? "hidden" : "visible",
            opacity: collapsed ? 0 : 1,
            transitionProperty: "width, visibility, opacity",
          }}
        >
          {/* SEARCH INPUT */}
          <Box sx={{ pt: 2, px: 1, display: "flex", gap: 1 }}>
            <Input
              sx={{ width: "100%" }}
              onChange={handleSearch}
              startDecorator={<SearchIcon />}
              placeholder="Search by Title"
            />
            <IconButton sx={{ width: "25px" }} onClick={() => setFilterOpen(true)}>
              <Filter/>
            </IconButton>
            <IconButton sx={{ width: "25px" }} onClick={() => setCollapsed(true)}>
              <MenuOpenIcon />
            </IconButton>
          </Box>
          <Typography level="body-xs" sx={{ px: 1, py: 1 }}>
            Applied filters {"(MM/DD/YYYY)"}:
          </Typography>
          <Box sx={{ display: "flex", px: 1, gap: 1, pb: 2 }}>
            <Chip>
              From: {fromTime.toLocaleDateString()}
            </Chip>
            <Chip>
              To: {toTime.toLocaleDateString()}
            </Chip>
          </Box>
          {/* VISITS LIST */}
          <Divider />
          <List
            sx={{
                [`& .${listItemButtonClasses.root}.${listItemButtonClasses.selected}`]: {
                borderLeft: "2px solid",
                borderLeftColor: "var(--joy-palette-primary-outlinedBorder)",
                },
                padding: "0px",
                width: "100%",
                position: "relative", 
            }}
            >
            {visitsLoading ? (
                <Box sx={{ p: 1 }}>
                  <CircularProgress size="sm" />
                </Box>
            ) : (
                visits &&
                visits.items.map((item) => {
                const nowDate = new Date();
                const yesterday = new Date();
                yesterday.setDate(nowDate.getDate() - 1);
                const date = new Date(`${item.created_at}Z`);

                let toAddHeader = date.toLocaleString("en-US", dateOptions);
                if (nowDate.toDateString() === date.toDateString()) {
                    toAddHeader = `Today - ${nowDate.toLocaleDateString(
                    "en-US",
                    dateOptions
                    )}`;
                } else if (yesterday.toDateString() === date.toDateString()) {
                    toAddHeader = `Yesterday - ${yesterday.toLocaleDateString(
                    "en-US",
                    dateOptions
                    )}`;
                }
                if (dateHeader.current !== toAddHeader) {
                    dateHeader.current = toAddHeader;
                    return (
                    <React.Fragment key={item.id}>
                        <Box
                        sx={{
                            display: "flex",
                            p: 1,
                            backgroundColor: "var(--main-blue-background)",
                        }}
                        >
                        <Typography level="body-sm">{toAddHeader}</Typography>
                        </Box>
                        <PastVisitCard visit={item} />
                    </React.Fragment>
                    );
                } else {
                    return <PastVisitCard visit={item} key={item.id} />;
                }
                })
            )}
            </List>


          {/* PAGINATION */}
          {visits && !visitsLoading && visits.items.length > 0 && (
            <Pagination
              count={Math.ceil(visits.total / 25)}
              page={page}
              onChange={handlePageChange}
            />
          )}
        </Box>

        {/* COLLAPSED BUTTON */}
        {collapsed && (
          <IconButton
            sx={{ width: { sm: "4%", md: "2%" }, backgroundColor: "rgba(0, 0, 0, 0.05)" }}
            onClick={() => setCollapsed(false)}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        )}

        {/* RIGHT-SIDE (OUTLET) */}
        <Sheet
          sx={{
            width: collapsed ? { sm: "96%", md: "98%" } : {xl: "75%", lg: "70%", md: "65%", sm: "60%"},
            height: "100vh",
          }}
        >
          <Outlet />
        </Sheet>
      </Box>
    </Sheet>
  );
}

export default PastVisits;
