import React from "react";
import { useContext, useEffect, useState } from "react";
import "../styling/NavigationBar.css";
import { Button, Typography } from "@mui/joy";
import { AlertContext } from "../context/AlertFlag";
import { getVisitCapacity } from "../services/VisitCapacity";
import { get_user_subscription, post_subscription } from "../services/Subscription";


function HelpComponent() {
    const { addAlert } = useContext(AlertContext); 
    const [ userTier, setUserTier ] = useState(null);
    const [ userCapacity, setUserCapacity ] = useState(null);
    const [ userCurrent, setUserCurrent ] = useState(null);

    const getUserTier = async () => {
        try {
            const response = await get_user_subscription();
            setUserTier(response['tier']);
            if (response.tier === 'free') {
                await getUserCapacity();
            }
        } catch (error) {
            addAlert(error.message, "danger");
        }
    }

    const getUserCapacity = async () => {
        try {
            const response = await getVisitCapacity();
            setUserCapacity(response.max_count);
            setUserCurrent(response.created_count);
        } catch (error) {
            addAlert(error.message, "danger");
        }
    }

    const upgradePlan = async () => {
        try {
            await post_subscription("premium");
            addAlert("Plan saved!", "success")
        } catch (error) {
            addAlert(error.message, "danger");
        }
    }

    useEffect( () => {
        getUserTier();
    }, [])

    return (<div id="help-section">
        <div id="background-color">
            {userTier === 'free' ? <>
                <Typography sx={{textAlign: "center"}}> You have {userCapacity - userCurrent} notes left this month. Upgrade to get more!</Typography>
                <Button onClick={upgradePlan} sx={{ padding: "5px", width: "75%", backgroundColor: "#2a3041", alignSelf: "center"}}> UPGRADE </Button>
            </>
            : <><h3> Help Desk</h3>
            <p>Tutorial? Watch <b/> <a href="https://www.youtube.com/watch?v=3XgemXhqB6A" target="_blank" rel="noopener noreferrer"> here </a> </p>
            <p>Need help? Reach us <b/> <a href="mailto:team@medxinsight.com" target="_blank" rel="noopener noreferrer"> here </a></p> </>}
        </div>
      </div>)
}

export default HelpComponent