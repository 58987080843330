import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Typography, CircularProgress, IconButton } from "@mui/joy";
import { Plus, RotateCcw } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { formatNoteName, formatNoteSections } from "../../utils/FormatNoteNameAndText";
import { NoteSections } from "../../utils/constants/Notes";
import { NoteNameIcon } from "../../utils/FormatNoteNameAndText";


function GenerateNotesListHorizontal({generate_notes, handleChange, handleRegen, handleAddNote, disabled=false }) {
    const [ generateNotes, setGenerateNotes ] = useState(null);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const generate_note_id = searchParams.get("generate_note_id");

    useEffect(() => {
        setGenerateNotes(generate_notes);
    }, [generate_notes])

    return <Box sx={{ px: 2, py: 2, display: "flex", gap: 1, width: { xl: "60vw", lg: "50vw", md: "45vw"}, minWidth: "300px", overflowX: "auto" }}>
        {generateNotes && generateNotes.map((item, index) => {
            return <Card key={item.id} sx={{ 
                    backgroundColor: generate_note_id === item.id ? "var(--joy-palette-primary-softBg)" : "white", 
                    width: "fit-content",  
                    border: "1px solid var(--joy-palette-divider)",
                    transition: "all 0.2s ease-in-out",
                    cursor: generate_note_id === item.id ? "not-allowed" : "pointer",
                    ...(generate_note_id === item.id ? {} : {"&:hover": {
                        backgroundColor: "var(--joy-palette-primary-softBg)", // Light hover effect
                        borderColor: "var(--joy-palette-primary-outlinedBorder)", // Change border on hover
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                    },
                    "&:active": {
                        transform: "scale(0.98)", // Button press effect
                    }})
                }}
                disabled={!item.generated_result || !item.generated_result.value}
                onClick={() => handleChange(index)}
            >
                <CardContent>
                    <Box sx={{ display: "flex", gap: 1.7, alignItems: "center"}}>
                        {item.generated_result && item.generated_result.value ? <NoteNameIcon note_type={item.note_type} size={"30px"}/> : <CircularProgress />}
                        <Box>
                            <Typography level="title-sm" sx={{ whiteSpace: "nowrap" }}>
                                {formatNoteName(item.note_type)}
                            </Typography>
                            <Typography level="body-xs" sx={{ fontStyle: "italic", color: "gray" }}>
                                {formatNoteSections(NoteSections.using_note_type(item.note_type).join())}
                            </Typography>
                        </Box>
                        {!disabled && <IconButton sx={{ width: "fit-content" }} disabled={!item.generated_result || !item.generated_result.value} onClick={handleRegen}>
                            <RotateCcw style={{ width: "17.5px" }} />
                        </IconButton>}
                    </Box>
                </CardContent>
            </Card>
        })}
        {!disabled && <Card key={"add-note"} sx={{ 
            backgroundColor: "white", 
            width: "fit-content",  
            border: "1px dashed var(--joy-palette-divider)",         
            transition: "all 0.2s ease-in-out",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "var(--joy-palette-primary-softBg)", // Light hover effect
                borderColor: "var(--joy-palette-primary-outlinedBorder)", // Change border on hover
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
            },
            "&:active": {
                transform: "scale(0.98)", // Button press effect
            } 
        }} 
        onClick={handleAddNote}>
            <CardContent sx={{ justifyContent: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Plus />
                    <Typography level='body-xs' sx={{ whiteSpace: "nowrap" }}>
                        Add Note
                    </Typography>
                </Box>
            </CardContent>
        </Card>}
    </Box>
}

export default GenerateNotesListHorizontal