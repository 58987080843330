import React, { useEffect, useState, useContext } from "react";
import { MicrophoneContext } from "../../../context/MicContext";
import { Select, Option, Box, Typography } from "@mui/joy";
import { Mic } from "@mui/icons-material";
import PropTypes from "prop-types";

const MicrophoneSelect = ({ disabled }) => {
  const { selectedMic, switchMicrophone, microphones, loadMicrophones } = useContext(MicrophoneContext);
  const [ stateMics, setStateMics ] = useState([]);

  const handleChange = async (e, mic) => {
    if (!mic) return;
    await switchMicrophone(mic);
  };

  useEffect(() => {
    setStateMics(microphones)
  }, [microphones])

  useEffect(() => {
    loadMicrophones();
  }, [])
  return (
    <Box sx={{ width: { xs: "100%", sm: "25%" } }}>
      <Typography level="body-sm"> Audio Source </Typography>
      <Select
        id="microphone-select"
        value={selectedMic}
        onChange={handleChange}
        disabled={disabled}
        startDecorator={
          <Mic />
        }
      >
        <Option value="" disabled>
          Select a microphone
        </Option>
        {stateMics && stateMics.map((mic) => (
          <Option key={mic.deviceId} value={mic.deviceId}>
            {mic.label || `Microphone ${mic.deviceId}`}
          </Option>
        ))}
      </Select>
    </Box>
  );
};

MicrophoneSelect.propTypes = {
  disabled: PropTypes.bool,
};

export default MicrophoneSelect;
