


export class PDFFormat {
    static pdf_drug_authorization = {
        "PlanMemberSignature": "TextField",
        "Date": "TextField",
        "plan_member_name": "TextField",
        "patient_name": "TextField",
        "plan_name": "TextField",
        "plan_numbe": "TextField", // Misspelled
        "plan_member_id": "TextField",
        "date_of_birth": "TextField",
        "address": "TextField",
        "telephone-contact": "TextField",
        "contact_by_email": "RadioGroup",
        "email_address": "TextField",
        "has_been_on_drug_before": "RadioGroup",
        "drug_start_date": "TextField",
        "drug_coverage_provider": "TextField",
        "has_drug_coverage": "RadioGroup",
        "insurance_company_name": "TextField",
        "plan_id_number": "TextField",
        "name_of_plan_member": "TextField",
        "relationship_to_patient": "TextField",
        "has_provincial_coverage": "RadioGroup",
        "provincial_program_name": "TextField",
        "additional_benefits_plan_details": "TextField",
        "additional_provincial_plan_details": "TextField",
        "receiving_disability_benefits": "RadioGroup",
        "enrolled_in_support_program": "RadioGroup",
        "support_program_id": "TextField",
        "contact_person_name": "TextField",
        "contact_phone_number": "TextField",
        "physician_name": "TextField",
        "physician_specialty": "TextField",
        "physician_address": "TextField",
        "physician_phone": "TextField",
        "physician_fax": "TextField",
        "prescribed_dose": "TextField",
        "patient_weight": "TextField",
        "date_weight_determined": "TextField",
        "medical_condition": "TextField",
        "date_of_diagnosis": "TextField",
        "health_canada_approved": "RadioGroup",
        "anticipated_treatment_duration": "TextField",
        "treatment_administration_location": "RadioGroup",
        "drug_name": "TextField",
        "drug_and/or_treatment_name_1": "TextField",
        "osing_regimen_1": "TextField", // Misspelled,
        "start_date_1": "TextField",
        "end_date_1": "TextField",
        "clinical_results_1": "RadioGroup",
        "clinical_results_details_1": "TextField",
        "drug_and/or_treatment_name_2": "TextField",
        "dosing_regimen_2": "TextField",
        "start_date_2": "TextField",
        "end_date_2": "TextField",
        "clinical_results_2": "RadioGroup",
        "clinical_results_details_2": "TextField",
        "drug_and/or_treatment_name_3": "TextField",
        "dosing_regimen_3": "TextField",
        "start_date_3": "TextField",
        "end_date_3": "TextField",
        "clinical_results_3": "RadioGroup",
        "clinical_results_details_3": "TextField",
        "is_clinical_trial": "RadioGroup",
        "has_diagnostic_testing": "RadioGroup",
        "specialties_and_clinics_1": "TextField",
        "most_recent_assessment_date_1": "TextField",
        "next_assessment_date_1": "TextField",
        "specialties_and_clinics_2": "TextField",
        "most_recent_assessment_date_2": "TextField",
        "next_assessment_date_2": "TextField",
        "specialties_and_clinics_3": "TextField",
        "most_recent_assessment_date_3": "TextField",
        "next_assessment_date_3": "TextField",
        "disease_severity": "TextField",
        "date_disease_determined": "TextField",
        "treatment_rationale": "TextField",
        "treatment_goals": "TextField",
        "start_date_of_treatment": "TextField",
        "clinical_benefit": "TextField",
        "response_description": "TextField",
        "disease_severity_stage": "TextField",
        "response_determination_date": "TextField",
        "renewal_request_additional_details": "TextField",
        "evidence_supporting_off_label_use": "RadioGroup",
        "signature": "TextField",
        "license_number": "TextField",
        "certification_date": "TextField",
    }
    static pdf_athlete_intake_form = {
        "EXAMINATION": "TextField",
        "NORMALLungs": "TextField",
        "NORMALAbdomen": "TextField",
        "NORMALNeck": "TextField",
        "NORMALBack": "TextField",
        "NORMALShoulderarm": "TextField",
        "NORMALElbowforearm": "TextField",
        "NORMALWristhandfingers": "TextField",
        "NORMALHipthigh": "TextField",
        "NORMALKnee": "TextField",
        "NORMALLegankle": "TextField",
        "NORMALFoottoes": "TextField",
        "Reason": "TextField",
        "M": "CheckBox",
        "Age": "TextField",
        "Not_Cleared_Recommendations": "TextField",
        "Allergies_1": "TextField",
        "Other_Comments_of_Note": "TextField",
        "Recommendations_1": "TextField",
        "Sports_Name": "TextField",
        "Athlete­_Name": "TextField",
        "Date_of_birth": "TextField",
        "Weight": "TextField",
        "Height": "TextField",
        "Blood_Pressure": "TextField",
        "Pulse": "TextField",
        "Vision_Right": "TextField",
        "Vision_Left": "TextField",
        "ABNORMAL_FINDINGS_Appearance_Marfan_stigmata_kyphoscoliosis_higharched_palate_pectus_excavatum_arachnodactyly_arm_span_height_hyperlaxity_myopia_MVP_aortic_insufficiency": "TextField",
        "NORMAL_Appearance_Marfan_stigmata_kyphoscoliosis_higharched_palate_pectus_excavatum_arachnodactyly_arm_span_height_hyperlaxity_myopia_MVP_aortic_insufficiency": "TextField",
        "NORMAL_Eyes_ears_nose_throat_Pupils_equal_Hearing": "TextField",
        "ABNORMAL_FINDINGS_Eyes_ears_nose_throat_Pupils_equal_Hearing": "TextField",
        "NORMALLymph_nodes": "TextField",
        "ABNORMAL_FINDINGSLymph nodes": "TextField",
        "NORMAL_Heart_a_Murmurs_auscultation_standing_supine_Valsalva_Location_of_point_of_maximal_impulse_PMI": "TextField",
        "ABNORMAL_FINDINGS_Heart_a_Murmurs_auscultation_standing_supine_Valsalva_Location_of_point_of_maximal_impulse_PMI": "TextField",
        "NORMAL_Pulses_Simultaneous_femoral_and_radial_pulses": "TextField",
        "ABNORMAL_FINDINGS_Pulses_Simultaneous_femoral_and_radial_pulses": "TextField",
        "ABNORMAL_FINDINGSLungs": "TextField",
        "ABNORMAL_FINDINGSAbdomen": "TextField",
        "NORMALGenitourinary_males": "TextField",
        "ABNORMAL_FINDINGSGenitourinary_males": "TextField",
        "NORMAL_Skin_HSV_lesions_suggestive_of_MRSA_tinea_corporis": "TextField",
        "ABNORMAL_FINDINGS_Skin_HSV_lesions_suggestive_of_MRSA_tinea_corporis": "TextField",
        "NORMALNeurologic_c": "TextField",
        "ABNORMAL_FINDINGSNeurologicc": "TextField",
        "ABNORMAL_FINDINGSNeck": "TextField",
        "ABNORMAL_FINDINGSBack": "TextField",
        "ABNORMAL_FINDINGSShoulderarm": "TextField",
        "ABNORMAL_FINDINGSElbowforearm": "TextField",
        "ABNORMAL_FINDINGSWristhandfingers": "TextField",
        "ABNORMAL_FINDINGSHipthigh": "TextField",
        "ABNORMAL_FINDINGSKnee": "TextField",
        "ABNORMAL_FINDINGSLegankle": "TextField",
        "ABNORMAL_FINDINGSFoottoes": "TextField",
        "ABNORMAL_FINDINGS_Functional_Duckwalk_single_leg_hop": "TextField",
        "NORMAL_Functional_Duckwalk_single_leg_hop": "TextField",
        "Male": "CheckBox",
        "Corrected_N": "CheckBox",
        "Corrected": "RadioGroup",
        "Cleareance_status": "RadioGroup",
        "Cleared_2": "RadioGroup",
        "Athlete_Name": "TextField",
        "Date_ of_birth": "TextField",
        "Not_Cleared_Reason": "TextField",
        "Name_of_physician_printtype": "TextField",
        "Date": "TextField",
        "Address": "TextField",
        "Phone": "TextField",
        "Date1": "TextField",
        "Address1": "TextField",
        "Phone1": "TextField",
        "Cleared_With_Restriction_with_reccomendations": "TextField",
        "For certain sports": "CheckBox",
        "Pending_further_evaluation": "CheckBox",
        "For_any_sports": "CheckBox",
        "For_certain_sports": "CheckBox",
    }
    static cb_exit_form = {
        "Athlete_Name1": "TextField",
        "Pre-Participation_Medical_Date": "TextField",
        "Comments": "TextField",
        "Injuries_medical_issues_during_event": "TextField",
        "Physician_treatments": "TextField",
        "Therapist_treatments": "TextField",
        "Medications": "TextField",
        "Discharge_status_and_plan": "TextField",
        "Athlete_Name2": "TextField",
        "Exit_Date1": "TextField",
        "Exit_Date2": "TextField",
        "CB - Exit Medical": "TextField",
    }
}