import React, { useContext, useState } from "react";
import PropTypes from 'prop-types';
import { Modal, ModalClose, ModalDialog, Box, Typography, Divider, Button } from "@mui/joy";
import { ChevronRight } from "lucide-react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import { AlertContext } from "../../context/AlertFlag";

function FilterPopover({ open, setClose, raise_selections}) {
    const { addAlert } = useContext(AlertContext);
    const [selectedPast, setSelectedPast ] = useState(() => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1);
        return currentDate;
      });
    const [selectedNow, setSelectedNow ] = useState(new Date());

    const handleSelectPast = (dateObject) => {
        try {
            if (dateObject) {
                if (dateObject.getTime() > selectedNow.getTime()) {
                    addAlert("You can't select a FROM date that's after the TO date!", "warning")
                } else {
                    setSelectedPast(dateObject);
                }
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    const handleSelectNow = (dateObject) => {
        if (dateObject.getTime() < selectedPast.getTime()) {
            addAlert("You can't select a TO date that's before the FROM date!", "warning")
        } else {
            setSelectedNow(dateObject);
        }
    }

    return <Modal aria-labelledby="modal-title"
    aria-describedby="modal-desc" open={open} onClose={setClose} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ModalDialog>
                <ModalClose variant="plain" sx={{ m: 1, width: "fit-content" }} />
                <Typography level="h4">
                    Filter
                </Typography>
                <Typography level="body-sm">
                    Apply a date range to search for your visits in a specific time frame
                </Typography>
                <Divider />
                <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                    <Box>
                        <Typography level="body-md">
                            From: {selectedPast.toDateString()}
                        </Typography>
                        <DayPicker mode="single" captionLayout="dropdown" defaultMonth={selectedPast} startMonth={new Date(2024, 0)} endMonth={new Date()} selected={selectedPast} onSelect={handleSelectPast} 
                        style={{ margin: 4 }}
                        />
                    </Box>
                    <ChevronRight style={{ width: "50px", height: "50px"}}/>
                    <Box>
                        <Typography level="body-md">
                            To: {selectedNow.toDateString()}
                        </Typography>
                        <DayPicker mode="single" captionLayout="dropdown" defaultMonth={selectedNow} startMonth={new Date(2024, 0)} endMonth={new Date()} selected={selectedNow} onSelect={handleSelectNow} 
                        style={{ margin: 4 }}
                        />
                    </Box>
                </Box>
                <Button onClick={() => {
                    raise_selections(selectedPast, selectedNow)
                    setClose();
                    }}> Apply </Button>
            </ModalDialog>
    </Modal>
}



FilterPopover.propTypes = {
    open: PropTypes.bool.isRequired,
    setClose: PropTypes.func.isRequired,
    raise_selections: PropTypes.func.isRequired,
}

export default FilterPopover;