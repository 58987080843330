import { Dropdown, MenuButton, IconButton, MenuItem, Menu } from "@mui/joy";
import MenuIcon from '@mui/icons-material/Menu';
import React from "react";
import { NavLink } from "react-router-dom";



function MobileMenuButton() {


    return (<Dropdown >
        <MenuButton slots={{ root: IconButton }} sx={{ width: '15%' }}>
            <MenuIcon/>
        </MenuButton>
        <Menu>
            <MenuItem
            onClick={() => {
                // Optionally tell the parent to shrink it, if that’s your desired behavior
                window.parent.postMessage("shrinkSidebar", "*");
            }}
            component={NavLink}
            to="/new-visit"
            >
            Current Visit
            </MenuItem>
            <MenuItem
            onClick={() => {
                // Send a message to the parent page to expand the sidebar
                window.parent.postMessage("expandSidebar", "*");
            }}
            component={NavLink}
            to="/past-visits"
            >
            Past Visits
            </MenuItem>
            <MenuItem component={NavLink} to='/account'>
                Settings
            </MenuItem>
            <MenuItem component={NavLink} to='/logout'>
                Sign Out
            </MenuItem>
        </Menu>
    </Dropdown>)
}

export default MobileMenuButton;