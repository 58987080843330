import React from "react";
import { Modal, Sheet, ModalClose, Typography, Button, FormControl } from "@mui/joy";
import PropTypes from "prop-types";


export function RegenerateNoteTypeModal({ open, closePopup, regenerate }) {

    const handleSubmit = async (event) => {
        event.preventDefault();
        await regenerate();
        closePopup();
    }

    return <Modal aria-labelledby="modal-title"
            aria-describedby="modal-desc" open={open} onClose={closePopup} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Sheet variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            display: "flex",
            flexDirection: "column",
          }}>
            <ModalClose variant="plain" sx={{ m: 1, width: "fit-content" }} />
            <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
            >
                Regenerate note
            </Typography>
            <Typography id="modal-desc" textColor="text.tertiary" paddingBottom='10px'>
                Please be aware that all of the edits made to the original note will be lost.
            </Typography>
            <form onSubmit={handleSubmit}>
                <FormControl>
                    <Button type="submit">CONFIRM</Button>
                </FormControl>
            </form>
        </Sheet>
    </Modal>
}


RegenerateNoteTypeModal.propTypes = {
    open: PropTypes.bool.isRequired,
    closePopup: PropTypes.func.isRequired,
    regenerate: PropTypes.func.isRequired,
}
