import React, { useState } from "react";
import { Modal, ModalClose, Typography, Sheet, FormControl, Button } from "@mui/joy";
import FloatingLabelInput from "../joy/FloatingInput";
import PropTypes from "prop-types";


// open, closePopup(), handleName()
function NameAppointmentPopup(props) {
    const [name, setName] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        props.handleName(name);
    }

    return <Modal aria-labelledby="modal-title"
    aria-describedby="modal-desc" open={props.open} onClose={(_event, reason) => {
        if (reason === "closeClick") {
            props.closePopup();
        }
    }}
    sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
        <Sheet variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            display: "flex",
            flexDirection: "column"
          }}>
            <ModalClose variant="plain" sx={{ m: 1, width: "fit-content" }}/>
            <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
            >
                Unnamed Appointment
            </Typography>
            <Typography id="modal-desc" textColor="text.tertiary">
                You haven&apos;t named your appointment. Would you like to set a name?
            </Typography>
            <form onSubmit={handleSubmit}>
                <FormControl>
                    <FloatingLabelInput label="Appointment Name" value={name} onChange={(event) => {
                        setName(event.target.value);
                    }}/>
                    <Button type="submit" sx={{ marginTop: "10px", width: "80%", alignSelf: "center"}} disabled={!name}>Set Name</Button>
                </FormControl>
            </form>
        </Sheet>
    </Modal>
}


NameAppointmentPopup.propTypes = {
    open: PropTypes.bool.isRequired,
    handleName: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired,
}

export default NameAppointmentPopup;