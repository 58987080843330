export async function get_microphone_permissions(setAudioStream, addAlert) {
    try {
        const audio = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false
        })
        setAudioStream(audio);
    } catch (error) {
        addAlert("Microphone permission denied.", "danger");
        throw new Error(error)
    }
}

export function setup_media_recorder(audio, push_audio_chunk) {
    let media_recorder;
    let audio_codecs = null;
    if ('mimeTypes' in navigator) {
        if (MediaRecorder.isTypeSupported('audio/webm; codecs=opus')) {
            media_recorder = new MediaRecorder(audio, { mimeType: 'audio/webm; codecs=opus' });
            audio_codecs = 'audio/webm; codecs=opus'
        } else
        if (MediaRecorder.isTypeSupported('audio/mpeg')) {
            media_recorder = new MediaRecorder(audio, { mimeType: 'audio/mpeg' });
            audio_codecs = 'audio/mpeg'
        }
        else if (MediaRecorder.isTypeSupported('audio/mp4; codecs=mp4a.40.2')) {
            media_recorder = new MediaRecorder(audio, { mimeType: 'audio/mp4; codecs=mp4a.40.2' });
            audio_codecs = 'audio/mp4; codecs=mp4a.40.2'
        } else if (MediaRecorder.isTypeSupported('audio/webm')) {
            media_recorder = new MediaRecorder(audio, { mimeType: 'audio/webm' });
            audio_codecs = 'audio/webm'
        } else if (MediaRecorder.isTypeSupported('audio/mp4')) {
            media_recorder = new MediaRecorder(audio, { mimeType: 'audio/mp4' });
            audio_codecs = 'audio/mp4'
        } else {
            media_recorder = new MediaRecorder(audio);
        }
    } else {
        media_recorder = new MediaRecorder(audio);
    }

    media_recorder.ondataavailable = (event) => {
        push_audio_chunk(event.data);
    }

    return { 
        "media_recorder": media_recorder,
        "audio_codecs": audio_codecs,
    }
}

export async function grab_wakelock() {
    const sentinel = await navigator.wakeLock?.request("screen");
    return sentinel;
}

export function clean_microphone(audio, mediaRecorder) {
    if (audio) {
        console.log("cleaned audio");
        audio.getTracks().forEach(track => track.stop());
    }

    if (mediaRecorder) {
        console.log("cleaned mediarecorder");
        mediaRecorder.stop();
        mediaRecorder.stream.getTracks().forEach(track => track.stop());
    }
}

export function areAllTracksLive(mediaRecorder) {
    if (!mediaRecorder?.stream) return false;
  
    return mediaRecorder.stream.getTracks().every(track => track.readyState === 'live');
}

export function areAllTracksLiveAudioNode(source) {
    if (!source?.mediaStream) return false;

    return source.mediaStream.getTracks().every(track => track.readyState === 'live');
}