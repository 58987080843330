import React, { useEffect, useState } from 'react';
import '../styling/NavigationBar.css';
import { Typography } from '@mui/joy';
import { axiosInstance } from "../services/Axios";
import PropTypes from "prop-types";


function SystemBanner (props) {
    const [systemStatus, setSystemStatus] = useState('');
    const [statusInfo, setStatusInfo] = useState('');
    const [display, setDisplay] = useState(false);

    async function getSystemStatus () {
        try {
          const response = await axiosInstance.get("/api/system_status");
          if (response.data['status'] === 'degraded') {
            localStorage.setItem('platformStatus', 'degraded');
            props.setVisible(true);
            setDisplay(true);
            setSystemStatus('degraded');
            setStatusInfo(response.data['additional_info']);
          } else if (localStorage.getItem('platformStatus') === 'degraded' && response.data['status'] === 'operational') {
            localStorage.setItem('platformStatus', null);
            props.setVisible(true);
            setDisplay(true);
            setSystemStatus('operational');
            setStatusInfo(response.data['additional_info']);
          }
        } catch (error) {
          console.log(error);
        }
      }

    useEffect(() => {
        getSystemStatus();
        let occasionallyPoll = setInterval( async () => {
            getSystemStatus();
        }, 1800000);

        return( () => {
            clearInterval(occasionallyPoll);
        })
    }, [])

    return <>
        {display && <div className={systemStatus === 'degraded' ? 'system-banner-degraded' : 'system-banner-operational'}>
            <Typography> {statusInfo} </Typography>
        </div>}
    </>
}

SystemBanner.propTypes = {
  setVisible: PropTypes.func.isRequired,

}


export default SystemBanner;