import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { User, NotepadText, MessagesSquare, Timer, Speech, Send, StepForward } from 'lucide-react';

import React, { useContext, useEffect, useState, useRef, useCallback } from "react"
import { useParams, useNavigate, useSearchParams } from "react-router-dom"
import { CircularProgress, Typography, Sheet, Box, Button, Divider, Input, IconButton } from "@mui/joy";
import { AlertContext } from "../context/AlertFlag";
import { convertTimeString, isWithinLast24Hours } from '../utils/TimeConversion';
import { delete_visit, get_visit, put_visit } from "../services/VisitRouter";
import useDebounce from '../hooks/useDebounce';
import NavBlocker from '../components/modals/NavBlocker';
import { NoteTextComponent } from '../components/custom/NoteTextComponent';
import ConfirmDeleteVisitModal from '../components/modals/ConfirmDeleteVisit';
import ConfirmEmailModal from '../components/modals/ConfirmSendEmail';
import { sendEmail } from '../services/Email';
import { formatNoteName } from '../utils/FormatNoteNameAndText';
import { RegenerateNoteTypeModal } from '../components/modals/RegenerateNote';
import { AddNoteModal } from '../components/modals/AddNoteModal';
import { delete_generate_note, put_generate_note, regenerate_note } from '../services/GenerateNote';
import GenerateNotesListHorizontal from '../components/custom/GenerateNotesListHorizontal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

function PastVisitsExpanded() {
    const { visitId } = useParams();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const generate_note_id = searchParams.get("generate_note_id");
    const { addAlert } = useContext(AlertContext);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { data: visit, isLoading: visitLoading, error: visitError } = useQuery({
        queryKey: ['visit', visitId],
        queryFn: () => get_visit(visitId),
        enabled: !!visitId,
        refetchOnMount: true,
    });
    // Since we're relying on get_visit for all gen_notes, we can invalidate entire cache for the visit
    const mutate_put_gen_note = useMutation({
        mutationFn: put_generate_note,
        onSuccess: () => {
            addAlert("Changes saved!", "success")
            queryClient.invalidateQueries({ queryKey: ['visit', visitId]})
        },
        onError: (error) => {
            addAlert("Unable to save changes", "danger")
            throw new Error(error)
        }
    });

    const mutate_regen_gen_note = useMutation({
        mutationFn: regenerate_note,
        onSuccess: () => {
            addAlert("Regen triggered!", "success")
            queryClient.invalidateQueries({ queryKey: ['visit', visitId]})
            queryClient.invalidateQueries({ queryKey: ['visits']})
        },
        onError: (error) => {
            addAlert("Unable to regenerate", "danger")
            throw new Error(error)
        }
    })

    const mutate_delete_gen_note = useMutation({
        mutationFn: delete_generate_note,
        onSuccess: () => {
            addAlert("Note deleted!", "success")
            searchParams.delete('generate_note_id');
            setSearchParams(searchParams);
            queryClient.invalidateQueries({ queryKey: ["visit", visitId]})
            queryClient.invalidateQueries({ queryKey: ['visits']})
        },
        onError: (error) => {
            addAlert("Unable to delete note.", "danger")
            throw new Error(error)
        }
    })

    const mutate_delete_visit = useMutation({
        mutationFn: delete_visit,
        onSuccess: () => {
            addAlert("Deleted!", "success")
            queryClient.invalidateQueries({ queryKey: ["visit", visitId]})
            queryClient.invalidateQueries({ queryKey: ['visits']})
            navigate('/past-visits');
        },
        onError: (error) => {
            addAlert("Unable to delete visit.", "danger")
            throw new Error(error)
        }
    })

    const mutate_put_visit = useMutation({
        mutationFn: put_visit,
        onSuccess: () => {
            addAlert("Changes saved!", "success")
            queryClient.invalidateQueries({ queryKey: ["visit", visitId]})
            queryClient.invalidateQueries({ queryKey: ['visits']})
        },
        onError: (error) => {
            addAlert("Unable to save changes.", "danger")
            throw new Error(error)
        }
    })

    const [ note, setNote ] = useState(null);
    const [ title, setTitle ] = useState(null);
    const [ editingNote, setEditingNote ] = useState(false);
    const [ editingTranscript, setEditingTranscript ] = useState(false);
    const [ view, setView ] = useState("note");
    const [ deleteVisit, setDeleteVisit ] = useState(false);

    const [ regenModal, setRegenModal ] = useState(false);
    const [ addNoteModal, setAddNoteModal ] = useState(false);
    const [ openConfirmSendEmail, setOpenConfirmSendEmail ] = useState(false);

    const noteRef = useRef(null);
    const transcriptRef = useRef(null);

    const chooseTranscript = () => {
        if (visit['user_edited_transcript']['value'].length <= 2 && visit['transcription'] && visit['transcription']['result']) {
            return visit['transcription']['result']['transcript']
        } else {
            return visit['user_edited_transcript']['value']
        }
    }

    const handleCancelTranscript = () => {
        setEditingTranscript(false);

        if (transcriptRef.current) {
            transcriptRef.current.textContent = chooseTranscript()
        }
    }

    const handleSaveNote = (text, note_id) => {
        mutate_put_gen_note.mutate({
            visit_id: visitId,
            generate_note_id: note_id,
            user_edited_result: text,
        })
    }

    const handleRegenerateNote = (note_id) => {
        mutate_regen_gen_note.mutate({
            visit_id: visitId,
            generate_note_id: note_id,
            transcript_choice: visit.user_edited_transcript.value.length > 0 ? 'user_edit' : 'deepgram',
        })
    }

    const handleDelete = () => {
        mutate_delete_visit.mutate({
            visit_id: visitId
        })
    }

    const handleDeleteNote = (note_id) => {
        if (visit.generate_notes.length <= 1) {
            addAlert("You must have at least one note per visit!", "danger");
            return;
        }
        mutate_delete_gen_note.mutate({
            visit_id: visitId,
            generate_note_id: note_id,
        })
    }

    const handleSaveTranscript = () => {
        setEditingTranscript(false);
        try {
            const request_body = {
                "title": title,
                "user_edited_transcript": {
                    "value": transcriptRef.current.innerText.trim(),
                }
            }
            mutate_put_visit.mutate({
                visit_id: visitId,
                request_body: request_body,
            })
            const note_types = [];
            for (let note of visit.generate_notes) {
                note_types.push(formatNoteName(note.note_type));
            }
            addAlert(`The following notes captured context from the previous transcript: ${note_types}`, "warning")
        } catch (error) {
            addAlert(error.message, "danger");
        }
    }

    const handleTitleChange = useCallback(() => {
        if (title && visit) {
            const request_body = {
                  "title": title,
                  "user_edited_transcript": {
                      "value": visit.user_edited_transcript.value,
                  }
            }
            mutate_put_visit.mutate({
                visit_id: visitId,
                request_body: request_body,
            })
        }
    }, [ title ])
    useDebounce(handleTitleChange, 1000, [title])

    const handleChangeNoteByIndex = (index) => {
        if (!index && index !== 0) return;
        const note_id = visit.generate_notes[index]['id']
        setSearchParams({ generate_note_id: note_id })
        setNote(visit.generate_notes[index]);
    }

    const handleTryContinueVisit = () => {
        try {
            if (!visit || !visit.transcription || !visit.transcription.result || !visit.transcription.result.transcript) {
                addAlert("Transcript not yet created", "danger");
                return;
            }
            navigate(`/visit/${visitId}/continue`)
        } catch (error) {
            addAlert(error.message, "danger");
            throw new Error(error)
        }
    }

    useEffect(() => {
        if (visitError) {
            addAlert(visitError.message, "danger");
        }
    }, [visitError])

    useEffect(() => {
        // If none set, set view to first visit
        if (visit && visit.generate_notes.length > 0) {
            if (!generate_note_id) {
                handleChangeNoteByIndex(0);
            } else {
                for (let note_index in visit.generate_notes) {
                    if (generate_note_id === visit.generate_notes[note_index].id) {
                        handleChangeNoteByIndex(note_index)
                        break;
                    }
                }
            }
        }
    }, [visit])

    return <Sheet sx={{width: "100%", height: "100vh", overflowY: "auto", overflowX: "hidden"}} key={visitId}>
        <NavBlocker dirty={editingNote || editingTranscript}/>
        {deleteVisit && <ConfirmDeleteVisitModal open={deleteVisit} on_close={() => {setDeleteVisit(false)}} delete_visit={handleDelete}/>}
        {openConfirmSendEmail && <ConfirmEmailModal
                    open={openConfirmSendEmail}
                    closePopupExternal={() => {
                        setOpenConfirmSendEmail(false);
                    }}
                    sendEmailExternal={async () => {
                        await sendEmail(visit.id);
                        addAlert("Sent email!", "success");
                    }}
                />}
        {visit && <Box>
            <Box sx={{display: "flex", justifyContent: "space-between", backgroundColor: "white", pl: 2, pr: 4, paddingTop: 3.75 }}>
                <Input defaultValue={visit.title} size="lg" variant="plain" onChange={(event) => {
                    setTitle(event.target.value)
                }} startDecorator={<User />} sx={{ fontSize: "larger", fontWeight: "600", backgroundColor: "white", width: "50%" }}/>
                <Box sx={{display: "flex", gap: 2 }}>
                    {/* <Button sx={{ display: "flex", width: "fit-content", whiteSpace: "nowrap" }} startDecorator={<CloudOutlinedIcon />}>
                        Sync to EMR
                    </Button> */}
                    <Button onClick={() => {setDeleteVisit(true)}} sx={{ display: "flex", backgroundColor: "white", width: "fit-content" }} startDecorator={<DeleteOutlineIcon/>} variant="outlined" color="danger"> Delete </Button>
                </Box>
            </Box>
            <Box sx={{display: "flex", justifyContent: "space-between", backgroundColor: "white", px: 4, py: 1, flexWrap: "wrap"}}>
                <Box sx={{display: "flex", gap: 1.7, alignItems: "center"}}>
                    <Timer style={{ color: "var(--joy-palette-text-tertiary, var(--joy-palette-neutral-600, #555E68))"}}/>
                    <Typography level="body-sm"> Created at: {convertTimeString(visit.created_at)}</Typography>
                    <Typography level="body-sm"> Updated at: {convertTimeString(visit.updated_at)}</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                    <Button sx={{ display: "flex", px: 2, whiteSpace: "nowrap", backgroundColor: "var(--dark-blue-button)", '&:hover': {backgroundColor: 'var(--dark-blue-button-hover)'}}} startDecorator={<StepForward />} onClick={handleTryContinueVisit} disabled={!isWithinLast24Hours(visit.created_at)}> Continue Visit </Button>
                    <Button sx={{ backgroundColor: "white", width: "fit-content", display: "flex" }} variant="plain" onClick={() => {
                            if (view === "note") {
                                setView("transcript");
                            } else {
                                setView("note");
                            }
                        }} startDecorator={view === "note" ? <Speech /> : <NotepadText />}> {view === "note" ? "Transcript" : "Note"} </Button>
                    <IconButton onClick={() => { setOpenConfirmSendEmail(true) }}>
                        <Send />
                    </IconButton>
                </Box>
            </Box>
            <Divider/>
            {view === "note" && 
            <>
                <GenerateNotesListHorizontal generate_notes={visit.generate_notes} handleChange={handleChangeNoteByIndex} handleRegen={() => setRegenModal(true)} handleAddNote={() => setAddNoteModal(true)}/>
                        {addNoteModal && <AddNoteModal open={addNoteModal} closePopup={() => { setAddNoteModal(false) }} visit_id={visitId} />}
                        {regenModal && <RegenerateNoteTypeModal open={regenModal} visit={visit} closePopup={() => { setRegenModal(false) }} regenerate={
                            () => {
                                handleRegenerateNote(note.id);
                            }}/>}
                <Divider />
            </>}
            <Box sx={{ backgroundColor: "var(--joy-palette-neutral-50)" }}>
                <Box sx={{ m: 2, py: 2, border: "1px solid var(--joy-palette-divider)", backgroundColor: "white", borderRadius: "12px" }}>
                    <Box sx={{ display: view === "note" ? "block" : "none" }}>
                        {visit && note && <NoteTextComponent key={visit.id} ref={noteRef} visit={visit} note={note} lift_editing={(value) => {setEditingNote(value)}} lift_save={handleSaveNote} lift_delete={handleDeleteNote}/>}
                    </Box>
                    <Box sx={{ display: view === "transcript" ? "block" : "none"}}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", py: 0.5, px: 2, gap: 1}}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <MessagesSquare style={{ color: "var(--main-blue)", height: "2.75em", width: "2.75em", paddingRight: 2}}/>
                                <Typography level="title-lg" sx={{pb: 0.4}}> Transcript </Typography>
                            </Box>
                            <Box sx={{ display: "flex", gap: 1 }}>
                                {editingTranscript && <Button sx={{ backgroundColor: "white", color: "gray" }} variant="plain" onClick={() => {
                                    handleCancelTranscript();
                                    setEditingTranscript(false);
                                }}>
                                    Cancel
                                </Button>}
                                <Button sx={{ backgroundColor: "white" }} variant="plain" onClick={() => {
                                    if (editingTranscript) {
                                        handleSaveTranscript();
                                    } else {
                                        setEditingTranscript(true);
                                    }
                                }}>
                                    {editingTranscript ? "Save" : "Edit"}
                                </Button>
                            </Box>
                        </Box>
                        <Divider sx={{ my: 1 }}/>
                        <Box sx={{whiteSpace: 'pre-wrap', p: 1, }}>
                            <Typography level="body-sm" sx={{ mt: 2, px: 2 }} ref={transcriptRef} contentEditable={editingTranscript} suppressContentEditableWarning={true}>
                                {chooseTranscript()}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>}
        {visitLoading && <Box sx={{ display: "flex", height: "70vh", alignItems: "center", justifyContent: "center"}}>
            <CircularProgress size="lg"/>
        </Box>}
    </Sheet>

}

export default PastVisitsExpanded;