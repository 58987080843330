import axios from "axios";
import { axiosInstance } from "../services/Axios";

const S3_TIMEOUT = 300000;

export async function getPresignedURL() {
    try {
        const response = await axiosInstance.get('/api/v1/upload/url');
        return response['data'];
    } catch (error) {
        throw new Error('Unable to fetch presigned URL')
    }
}


export async function putAudioIntoPresigned(presignedFields, audioBlob) {
    const formData = new FormData();

    Object.keys(presignedFields['fields']).forEach(key => {
        formData.append(key, presignedFields['fields'][key]);
    })

    formData.append('file', audioBlob);

    try {
        if (audioBlob.size < 5) {
            throw new Error("File too small")
        }
        console.log(`Uploading blob size of ${audioBlob.size}`)
        const response = await axios.post( presignedFields.url, formData, {
            timeout: S3_TIMEOUT
        })
        if (!response) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        if (response.status < 200 || response.status >= 300) {
            throw new Error(
              `Upload failed: Received unexpected status code ${response.status}`
            );
        }
        
        console.log('File successfully uploaded');

        return response;
    } catch (error) {
        throw new Error(`${error.message} with size: ${audioBlob.size}`, 400)
    }
}

function partitionBySize(blob, chunkSizeMB = 6) {
    const chunkSizeBytes = chunkSizeMB * 1024 * 1024; // Convert MB to bytes
    const totalSize = blob.size;
    const minChunkSizeBytes = 6 * 1024 * 1024; // Minimum part size (6 MB)
    const chunks = [];

    let start = 0;

    while (start < totalSize) {
        // Default chunk size
        let end = start + chunkSizeBytes;

        // Check if the remaining size is smaller than the minimum size
        if (totalSize - end < minChunkSizeBytes) {
            end = totalSize; // Include all remaining bytes in the last chunk
        }

        chunks.push(blob.slice(start, end)); // Create the chunk
        console.log(`Chunk: Start=${start}, End=${end}, Size=${end - start}`);
        start = end; // Move to the next chunk
    }

    return { chunks: chunks, parts: chunks.length };
}

export async function putIntoMultipartPresigned(blob = null) {
    try {
        const new_partition = partitionBySize(blob)
        const response_multipart = await axiosInstance.post("/api/v1/upload/url/multipart", { "parts": new_partition['parts'] });

        const etags = [];
        for (let part_index = 0; part_index < new_partition['parts']; part_index ++) {
            const url = response_multipart.data['presigned_urls'][part_index]['presigned_url'];

            let response;
            for (let retry = 0; retry < 2; retry++) {
                try {
                    response = await axios.put(url, new_partition.chunks[part_index], {
                        timeout: S3_TIMEOUT
                    })
                    if (response.status < 200 || response.status >= 304) {
                        throw new Error("Partition failed")
                    }
                    break;
                } catch (error) {
                    console.log("Partition failed");
                    continue;
                }
            }
            console.log(`${part_index + 1} complete`)
            etags.push({
                'PartNumber': part_index + 1,
                'ETag': response.headers.etag.replace(/^"|"$/g, '')
            })
        }

        await axiosInstance.post(`/api/v1/upload/url/multipart/${response_multipart.data['upload_id']}`, { "object_name": response_multipart.data['object_name'], "etags": etags })

        return response_multipart.data['object_name']
    } catch(error) {
        console.log(error);
        throw new Error(error);
    }
}