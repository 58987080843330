import React, { useEffect, useRef, useState } from "react";
import { Box, Select, List, ListItem, Typography, Option } from "@mui/joy";

import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { capitalizeWords, formatNoteName } from "../../utils/FormatNoteNameAndText";
import { NoteSections, PrivateMapping } from "../../utils/constants/Notes";
import PropTypes from "prop-types";
import { getEmail } from "../../services/Authentication";


export function NoteTypeSelect({onChange, value, disabled, sx, calmTitle, noteViews, ...rest}) {
    const [ sections, setSections ] = useState([]);
    const [ email, setEmail ] = useState(null);

    useEffect(() => {
        setEmail(getEmail());
        setSections(noteViews);
    }, [noteViews])

    return <Box sx={{display: "flex", alignItems: "center"}}>
        {!disabled && calmTitle && <h3>Note Type: </h3>}
        {calmTitle && disabled && <Typography> Note Type: </Typography>}
        <Select
        placeholder="Note Type"
        name="note_select"
        startDecorator={<AppRegistrationIcon />}
        onChange={onChange}
        value={value}
        disabled={disabled}
        sx={sx}
        {...rest}
        >
        {/* <Typography
            level="body-sm"
            sticky
            sx={{ textTransform: "uppercase", pointerEvents: "none", px: 1, py: 1}}
        >
            Premium Notes:
        </Typography> */}
        {
            Object.entries(PrivateMapping).map(([section, notes]) => {
                if (!email || email == "null") {
                    return null;
                }
                if (PrivateMapping.private_notes_by_email(email).includes(section)) {
                        return <React.Fragment key={section}>
                        {/* You can still show a header if needed */}
                        <Typography
                            level="body-xs"
                            sticky
                            sx={{ textTransform: "uppercase", pointerEvents: "none", px: 1.5, py: 1}}
                        >
                            {capitalizeWords(section, "_")}
                        </Typography>
                        {notes.map((note) => {
                            return <Option key={note} value={note}>
                                {formatNoteName(note)}
                            </Option>
                        })}
                    </React.Fragment>
                }
            })
        }
        {/* <Typography
            level="body-sm"
            sticky
            sx={{ textTransform: "uppercase", pointerEvents: "none", px: 1, py: 1}}
        >
            OneChart Notes:
        </Typography> */}
        {Object.entries(NoteSections).map(([section, notes]) => {
            if (!sections.includes(section) || section === "pdf_forms") {
                // If not in the list, skip rendering or return null
                return null;
            }
            return <React.Fragment key={section}>
                {/* You can still show a header if needed */}
                <Typography
                    level="body-xs"
                    sticky
                    sx={{ textTransform: "uppercase", pointerEvents: "none", px: 1.5, py: 1}}
                >
                    {capitalizeWords(section, "_")}
                </Typography>
                {notes.map((note) => {
                    return <Option key={note} value={note}>
                        {formatNoteName(note)}
                    </Option>
                })}
            </React.Fragment>
            }
        )}
        </Select>
    </Box>
}

NoteTypeSelect.propTypes = ({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    sx: PropTypes.object,
    calmTitle: PropTypes.bool,
    noteViews: PropTypes.arrayOf(PropTypes.string),
})

NoteTypeSelect.defaultProps = {
    value: null,
    disabled: false,
    calmTitle: false,
    noteViews: null,
}