import React from "react"
import { OctagonX } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom"
import { Sheet, Typography, Button, Stack } from "@mui/joy";



function ErrorPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const message = location.state?.message || "Something bad happened..."
    const redirectURL = location.state?.redirectURL || null

    return <Sheet sx={{ height: "80vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Stack sx={{ alignItems: "center", gap: 2 }}>
            <OctagonX style={{ color: "red", height: "50px", width: "50px" }}/>
            <Typography> {message} </Typography>
            <Button variant="neutral" sx={{ background: "white" }} onClick={() => { 
                redirectURL ? navigate(redirectURL) : navigate("/past-visits")
            }}> Go back</Button>
        </Stack>
    </Sheet>
}

export default ErrorPage;