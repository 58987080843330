import { getVisitCapacity } from "../services/VisitCapacity";
import { get_visit } from "../services/VisitRouter";
import { get_generate_note } from "../services/GenerateNote";


// Check status of all elements before 
export async function checkVisitAllowed(time, addAlert) {

    if (time >= 9000) {
        addAlert("You've hit the recording limit of 2 hours. Please hit 'complete' to save the note for your visit.", 'danger');
        throw new Error("Recording Limit reached")
    }

    const visitCapacity = await getVisitCapacity();
    if (!visitCapacity && !visitCapacity.can_create) {
        addAlert("You’ve reached the maximum number of daily visits for your subscription. Upgrade for more daily visits", 'danger');
        throw new Error("Visit max reached")
    }
}

function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function check_visit_status(visit_id, signal) {
    try {
        while (true) {
            if (signal?.aborted) {
                console.log("Polling canceled (aborted).");
                return;
            }
            const response = await get_visit(visit_id);
            if (response['transcription'] !== null) {
                return response
            }
            await delay(2500);
        }
    } catch (error) {
        throw new Error(error);
    }
};

export async function check_note_status (visit_id, note_id, signal) {
    try {
        while (true) {
            if (signal?.aborted) {
                console.log("Polling canceled (aborted).");
                return;
            }
            const response = await get_generate_note({visit_id, note_id});
            if (response['generated_result']['value']) {
                return response
            }
            await delay(3500);
        }
    }
    catch(error) {
        throw new Error(error);
    }
}