import React, { createContext, useState, useRef, useCallback, useContext } from 'react';
import { areAllTracksLive, areAllTracksLiveAudioNode, setup_media_recorder } from '../utils/AudioUtils';
import { AlertContext } from './AlertFlag';
import { isSafari } from '../utils/UserSystem';

const MicrophoneContext = createContext();
const AudioContext = createContext();

export const AudioProvider = React.memo(({ children }) => {
  const { addAlert } = useContext(AlertContext);
  // Microphone
  const [ selectedMic, setSelectedMic ] = useState(null);
  const [ canLoadMic, setCanLoadMic ] = useState(false);
  const [ microphones, setMicrophones ] = useState([]);
  const [ isReady, setIsReady ] = useState(false);

  // Audio
  const [ recordingState, setRecordingState ] = useState(null);
  const [ recordingTime, setRecordingTime ] = useState(0);
  const recordingTimerRef = useRef(null);

  // Persistent locations
  const audioContextRef = useRef(null);
  const audioDeviceRef = useRef(null);
  const destinationRef = useRef(null);
  const currentSourceRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const audioCodecs = useRef(null);

  // Check mic -> destination stream and destination stream -> media recorder, and ensure everything's alive
  // This function is called before mediarecorder creation AND before start recording.
  // ** There is a risk that context or tracks are scrubbed when audio is paused. However, this means we would have to recreate the mediarecorder entirely. Will revisit when needed.
  const ensureStreamsAlive = useCallback(() => {
    try {
      // mic -> destination
      if ((!areAllTracksLiveAudioNode(currentSourceRef.current)) && audioDeviceRef.current) {
        const newSource = audioContextRef.current.createMediaStreamSource(audioDeviceRef.current);
        newSource.connect(destinationRef.current);
        currentSourceRef.current = newSource;
        console.log("Refreshed source")
      }

      // destination -> media recorder
      if (!areAllTracksLive(destinationRef.current)) {
        const destination = audioContextRef.current.createMediaStreamDestination();
        destinationRef.current = destination;
        console.log("Refreshed destination")
        if (currentSourceRef.current) {
          currentSourceRef.current.connect(destinationRef.current);
          console.log("Reconnected source")
        }
        const setup_media = setup_media_recorder(destinationRef.current.stream, (chunk) => {
          recordedChunksRef.current.push(chunk);
        });
        mediaRecorderRef.current = setup_media.media_recorder
        audioCodecs.current = setup_media.audio_codecs
        console.log("Recreated media recorder as well.");
      }
    } catch (error) {
      throw new Error(error)
    }
  }, [destinationRef, currentSourceRef])

  // On swap, grab the mic with said specific ID, deleted old connection, then setup a new connection to media stream node.
  const switchMicrophone = useCallback(async (deviceId) => {
    if (audioDeviceRef.current) {
      audioDeviceRef.current.getTracks().forEach(track => track.stop());
      audioDeviceRef.current = null;
    }


    const newMicStream = await navigator.mediaDevices.getUserMedia({ 
      audio: { deviceId: { exact: deviceId } } 
    });

    if (!newMicStream) {
      throw new Error("Could not retrieve microphone")
    }

    if (currentSourceRef.current) {
      currentSourceRef.current.disconnect();
    }

    audioDeviceRef.current = newMicStream;

    if (audioContextRef.current) {
      const newSource = audioContextRef.current.createMediaStreamSource(newMicStream);
      newSource.connect(destinationRef.current)
      currentSourceRef.current = newSource;
    }
    setSelectedMic(deviceId);
    setIsReady(true);
  }, [selectedMic]);

  const loadMicrophones = useCallback(async () => {
    try {
      // Needed to enumerate 
      if (isSafari()) {
        const grab_permissions = await navigator.mediaDevices.getUserMedia({ audio: true });
        grab_permissions.getTracks().forEach(track => track.stop());
      }

      const devices = await navigator.mediaDevices.enumerateDevices();
      const mics = devices.filter((device) => device.kind === "audioinput");

      setMicrophones(mics);

      if (mics.length > 0 && !selectedMic) {
        await switchMicrophone(mics[0].deviceId);
      } else if (selectedMic) {
        await switchMicrophone(selectedMic);
      }
      setRecordingState('inactive');
    } catch(error) {
      throw new Error(error);
    }
  }, [selectedMic, switchMicrophone, canLoadMic, isReady])

  // Initialize recording will be called on start audio and will create:
  // 1. Audio context and media stream destination
  // 2. Connect current destination with whatever mic is currently being used
  // 3. Initialize media recorder with destination stream
  const initializeRecording = useCallback(async () => {
    try {
      const micStream = await navigator.mediaDevices.getUserMedia({ 
        audio: selectedMic ? {
          deviceId: { exact: selectedMic }
        } : true
      });
      // 1.
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      audioContextRef.current = audioContext;
      const destination = audioContextRef.current.createMediaStreamDestination();
      destinationRef.current = destination;
      // 2.
      const newSource = audioContextRef.current.createMediaStreamSource(micStream);
      newSource.connect(destinationRef.current)
      currentSourceRef.current = newSource;

      // 3.
      const setup_media = setup_media_recorder(destinationRef.current.stream, (chunk) => {
        recordedChunksRef.current.push(chunk);
      });
      mediaRecorderRef.current = setup_media.media_recorder
      audioCodecs.current = setup_media.audio_codecs
    } catch (error) {
      addAlert(error.message, "danger");
      throw new Error(error);
    }
  }, [recordingState])

  // On start record, initialize stream destination
  const startRecording = useCallback(() => {
    if (!mediaRecorderRef.current) return false;
    
    try {
      recordedChunksRef.current = [];
      mediaRecorderRef.current.start(2000); // Collect data every second
      setRecordingState('recording');
      setRecordingTime(0);
      recordingTimerRef.current = setInterval(() => {
        setRecordingTime(prev => prev + 1);
      }, 1000);
    } catch(error) {
      throw new Error(error);
    }
  }, [recordingState])

  // On pause, pause mediarecorder up here
  const pauseRecording = useCallback(() => {
    if (!mediaRecorderRef.current || recordingState !== 'recording') return false;

    try {
      mediaRecorderRef.current.pause();
      setRecordingState('paused');

      clearInterval(recordingTimerRef.current);
    } catch(error) {
      throw new Error(error);
    }
  }, [recordingState])

  // On unpause, unpause mediarecorder
  const unPauseRecording = useCallback(() => {
    if (!mediaRecorderRef.current || recordingState !== 'paused') return false;

    try {
      ensureStreamsAlive();
      mediaRecorderRef.current.resume();
      setRecordingState('recording');

      recordingTimerRef.current = setInterval(() => {
        setRecordingTime(prev => prev + 1);
      }, 1000);
    } catch(error) {
      throw new Error(error);
    }
  }, [recordingState])

  // on stop, stop mediarecorder finalize recording state. Blob and give back
  const stopRecording = useCallback(() => {
    if (!mediaRecorderRef.current) return null; 
    if (!recordingState) throw new Error("Recording state has not been started so stopping is impossible.");

    try {
      mediaRecorderRef.current.requestData();
      mediaRecorderRef.current.stop();
      setRecordingState('inactive');

      clearInterval(recordingTimerRef.current);
      
      let blob;
      if (audioCodecs.current) {
        blob = new Blob(recordedChunksRef.current, {
          type: audioCodecs.current,
        })
      } else {
        blob = new Blob(recordedChunksRef.current)
      }

      return blob;
    } catch(error) {
      throw new Error(error);
    }
  }, [recordingState])

  // Cleanup any recorders to get rid of memory leaks 
  // Cleanup states as well
  const cleanUpRecording = useCallback(() => {
    if (mediaRecorderRef.current && recordingState === 'inactive' ) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
    }

    if (destinationRef.current?.stream) {
      destinationRef.current.stream.getTracks().forEach(track => track.stop());
      destinationRef.current = null;
    }

    if (currentSourceRef.current) {
      currentSourceRef.current.disconnect();
      currentSourceRef.current = null;
    }

    if (audioDeviceRef.current) {
      audioDeviceRef.current.getTracks().forEach((track) => track.stop());
      audioDeviceRef.current = null;
    }

    if (audioContextRef.current) {
      if (audioContextRef.current.state !== 'closed') audioContextRef.current?.close();
      audioContextRef.current = null;
    }

    clearInterval(recordingTimerRef.current);
    setRecordingState(null);
    setIsReady(false);
    setCanLoadMic(false);
    setRecordingTime(0);
    recordedChunksRef.current = [];
  }, [recordingState])

  const prepForNextVisit = useCallback(() => {
    setIsReady(true);
    setRecordingState('inactive');
    if (selectedMic) switchMicrophone(selectedMic);
  }, [recordingState])

  // Exposed contexts
  const microphoneValue = {
    selectedMic,
    microphones,
    isReady,
    switchMicrophone,
    loadMicrophones
  };
  
  // Recorder context value
  const recorderValue = {
    recordingState,
    recordingTime,
    audioDeviceRef,
    audioContextRef,
    initializeRecording,
    startRecording,
    pauseRecording,
    unPauseRecording,
    stopRecording,
    cleanUpRecording,
    prepForNextVisit,
  };
  
  return (
    <MicrophoneContext.Provider value={microphoneValue}>
      <AudioContext.Provider value={recorderValue}>
        {children}
      </AudioContext.Provider>
    </MicrophoneContext.Provider>
  );
})

export const useMicrophone = () => useContext(MicrophoneContext);
export const useRecorder = () => useContext(AudioContext);

export { MicrophoneContext, AudioContext };


