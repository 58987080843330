import { Select, Option } from "@mui/joy";
import React, { useState } from "react"
import { ConversationLanguage, mapLanguageNames } from "../../utils/constants/Languages";
import { Languages } from "lucide-react";



export function InputLanguageSelect({ defaultValue, onChange, ...rest }) {
    const [language, setLanguage] = useState(defaultValue);

    const handleSelectChange = (event, newValue) => {
        setLanguage(newValue);
        onChange(newValue);
    }

    return <Select value={language} onChange={handleSelectChange} startDecorator={<Languages />} {...rest}>
        {Object.values(ConversationLanguage).map((language) => (
            <Option key={language} value={language}> {mapLanguageNames(language)}</Option>
        ))}
    </Select>

}