import axios from "axios";
import { clearUser, getToken } from "./Authentication";


// For axios calls with auth required
export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_HOST, // Always use REACT_APP_HOST from .env
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use((config) => {
    const token = getToken(); // Retrieve the latest token
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`; // Set the Authorization header
    }
    return config;
}, (error) => {
    // Handle request error here
    return Promise.reject(error);
});


axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 403) { 
            clearUser();
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

