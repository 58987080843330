import React, { useState } from "react";
import { convertTimeString } from "../utils/TimeConversion";
import "../styling/AccountAndSettingsView.css";
import "../styling/LoginView.css";
import { useNavigate } from "react-router-dom";
import BillingView from "./BillingPage";
import SettingsView from "./SettingsPage";
import { CircularProgress, Button, Input, Typography, Box } from "@mui/joy";
import UpdateUserAccountModal from "../components/modals/UpdateUserAccount";
import { HyperLink } from "../components/text/Hyperlink";
import { get_user_account } from "../services/UserAccount";
import { useQuery } from "@tanstack/react-query";

function AccountPage() {
  const navigate = useNavigate();
  const { data, isLoading, error } = useQuery({
    queryKey: ['user'],
    queryFn: get_user_account,
  })

  const [editUserAccount, setEditUserAccount] = useState(false);

  const goChangePassword = () => {
    navigate("/reset-password");
  };

  const goLogout = () => {
    navigate("/logout");
  };

  return (
    <Box>
      {isLoading && (
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size="lg" />
        </Box>
      )}

      
      {data && (
        <UpdateUserAccountModal
          user={data}
          open={editUserAccount}
          onClose={() => setEditUserAccount(false)}
        />
      )}

      {!isLoading && (
        <Box className="account-page">
          <BillingView />
          <Box
            id="outline-it"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              border: "1px solid #ccc",
              padding: "1rem",
            }}
          >
            <Typography level="h2">Profile</Typography>

            {/* Single-row layout for Name, Email, Specialty, and Practice Type */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 3,
                whiteSpace: "nowrap",
                overflowX: "auto",
                paddingBottom: "0.5rem",
              }}
            >
              {/* Name */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  minWidth: "180px",
                }}
              >
                <Typography>Name:</Typography>
                <Input
                  variant="outlined"
                  disabled
                  placeholder={data.full_name}
                  size="md"
                  sx={{ textAlign: "center" }}
                />
              </Box>

              {/* Email */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  minWidth: "180px",
                }}
              >
                <Typography>Email:</Typography>
                <Input
                  variant="outlined"
                  disabled
                  placeholder={data.email}
                  size="md"
                  sx={{ textAlign: "center" }}
                />
              </Box>

              {/* Specialty */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  minWidth: "180px",
                }}
              >
                <Typography>Specialty:</Typography>
                <Input
                  variant="outlined"
                  disabled
                  placeholder={data.specialty || "Not specified"}
                  size="md"
                  sx={{ textAlign: "center" }}
                />
              </Box>

              {/* Practice Type */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  minWidth: "180px",
                }}
              >
                <Typography>Practice Type:</Typography>
                <Input
                  variant="outlined"
                  disabled
                  placeholder={data.practice || "Not specified"}
                  size="md"
                  sx={{ textAlign: "center" }}
                />
              </Box>
            </Box>

            {/* Last Updated */}
            <Box sx={{ textAlign: "left", mt: 0}}>
              <Typography>Last updated at: {data.updatedAt}</Typography>
            </Box>

            {/* Terms Acknowledgement */}
            <Box sx={{ textAlign: "left", mt: 1 }}>
              <Typography>
                You acknowledged the{" "}
                <HyperLink
                  text="terms of service"
                  link="https://onechart.ai/privacy/"
                />{" "}
                and{" "}
                <HyperLink
                  text="privacy policy"
                  link="https://onechart.ai/privacy/"
                />{" "}
                on {convertTimeString(data.created_at)}
              </Typography>
            </Box>

            {/* Action Buttons */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                justifyContent: "space-evenly",
                mt: 2,
              }}
            >
              <Button
                variant="plain"
                onClick={() => setEditUserAccount(true)}
                sx={{
                  width: "30vw",
                  fontWeight: "normal",
                  minHeight: "2.5rem",
                  backgroundColor: "var(--light-grey)",
                  color: "var(--main-blue)",
                }}
              >
                UPDATE ACCOUNT
              </Button>
              <Button
                variant="plain"
                onClick={goChangePassword}
                sx={{
                  width: "30vw",
                  fontWeight: "normal",
                  minHeight: "2.5rem",
                  backgroundColor: "var(--light-grey)",
                  color: "var(--main-blue)",
                }}
              >
                CHANGE PASSWORD
              </Button>
              <Button
                variant="plain"
                onClick={goLogout}
                sx={{
                  width: "30vw",
                  fontWeight: "normal",
                  minHeight: "2.5rem",
                  backgroundColor: "var(--light-grey)",
                  color: "red",
                }}
              >
                SIGN OUT
              </Button>
            </Box>
          </Box>
          <SettingsView />
        </Box>
      )}
    </Box>
  );
}

export default AccountPage;
