import React, { useContext, useState } from "react";
import {
  Modal,
  ModalDialog,
  ModalClose,
  Button,
  Typography,
  Box,
  FormControl,
} from "@mui/joy";
import FloatingLabelInput from "../../components/joy/FloatingInput";
import Autocomplete from "@mui/joy/Autocomplete";
import TextField from "@mui/joy/TextField";
import { AlertContext } from "../../context/AlertFlag";
import { put_user_account } from "../../services/UserAccount";
import { formatPhoneNumber } from "../../utils/FormatNoteNameAndText";
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const practiceTypes = [
    "Private Practice",
    "Hospital-Based Practice",
    "Academic/Teaching Institution",
    "Outpatient Clinic/Group Practice"
  ];
  
  const specialties = [
    "Family Medicine",
    "Internal Medicine",
    "Pediatrics",
    "Geriatrics",
    "Cardiology",
    "Endocrinology",
    "Gastroenterology",
    "Hematology",
    "Infectious Disease",
    "Nephrology",
    "Neurology",
    "Oncology",
    "Pulmonology",
    "Rheumatology",
    "General Surgery",
    "Orthopedic Surgery",
    "Neurosurgery",
    "Cardiothoracic Surgery",
    "Vascular Surgery",
    "Plastic Surgery",
    "Otolaryngology (ENT) Surgery",
    "Urology",
    "Obstetrics",
    "Gynecology",
    "Reproductive Endocrinology and Infertility",
    "Maternal-Fetal Medicine",
    "Emergency Medicine",
    "Critical Care Medicine",
    "Trauma Surgery",
    "Psychiatry",
    "Psychology",
    "Addiction Medicine",
    "Dermatology",
    "Ophthalmology",
    "Allergy and Immunology",
    "Physical Medicine and Rehabilitation (PM&R)",
    "Pain Management",
    "Sleep Medicine",
    "Radiology",
    "Pathology",
    "Anesthesiology",
    "Occupational Medicine",
    "Interventional Cardiology",
    "Pediatric Cardiology",
    "Geriatric Psychiatry",
    "Pediatric Neurology",
    "Sports Medicine",
    "Telemedicine",
    "Integrative and Holistic Medicine",
    "Preventive Medicine",
    "Palliative Care",
    "Genetic Counseling",
    "Physiotherapy",
    "Behavioral Health",
    "Speech-Language Pathology",
    "Nutrition and Dietetics",
    "Chiropractic Medicine",
    "Audiology",
    "Pain Rehabilitation",
    "Other",
  ];

export default function UpdateUserAccountModal({ user, open, onClose}) {
  const { addAlert } = useContext(AlertContext);

  // Split the user's existing full name
  const [firstName, setFirstName] = useState(
    user?.full_name?.split(" ")[0] || ""
  );
  const [lastName, setLastName] = useState(
    user?.full_name?.split(" ")[1] || ""
  );
  const [phoneNumber, setPhoneNumber] = useState(user?.phone_number || "");
  const [specialty, setSpecialty] = useState(user?.specialty || null);
  const [practiceType, setPracticeType] = useState(user?.practice || null);

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: put_user_account,
    onSuccess: () => {
      addAlert("Account updated successfully!", "success");
      queryClient.invalidateQueries({ queryKey: ['user'] }); // refresh
    },
    onError: (error) => {
      addAlert("Couldn't update account.", "danger");
      throw new Error(error)
    }
  })

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      full_name: `${firstName} ${lastName}`,
      phone_number: phoneNumber,
      specialty,
      practice: practiceType,
    };

    mutation.mutate(payload);
  };

  return (
    <Modal open={open} onClose={onClose} variant="soft">
      <ModalDialog
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          paddingBottom: "1rem",
        }}
      >
        <ModalClose sx={{ width: "auto" }} />

        <Typography level="h4" sx={{ fontWeight: 600 }}>
          Update Account Details
        </Typography>
        <Typography level="body2" sx={{ color: "text.secondary" }}>
          Modify your personal and practice information
        </Typography>

        <form onSubmit={handleSubmit}>
          <FormControl
            sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 1 }}
          >
            {/* Row: First Name + Last Name */}
            <Box sx={{ display: "flex", gap: 2 }}>
              <FloatingLabelInput
                label="First Name"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                required
                sx={{ flex: 1 }}
              />
              <FloatingLabelInput
                label="Last Name"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                required
                sx={{ flex: 1 }}
              />
            </Box>

            {/* Phone Number */}
            <FloatingLabelInput
              label="Phone Number"
              value={formatPhoneNumber(phoneNumber)}
              onChange={(event) => setPhoneNumber(event.target.value)}
              required
            />

            {/* Specialty */}
            <Autocomplete
              placeholder="Select Specialty"
              forcePopupIcon={false}
              options={specialties}
              value={specialty}
              disableClearable
              onChange={(_, newValue) => setSpecialty(newValue)}
              sx={{ width: "100%" }} 
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Specialty" />
              )}
            />

            {/* Practice Type */}
            <Autocomplete
              placeholder="Select Practice Type"
              forcePopupIcon={false}
              options={practiceTypes}
              value={practiceType}
              disableClearable
              onChange={(_, newValue) => setPracticeType(newValue)}
              sx={{ width: "100%" }} 
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Practice Type" />
              )}
            />

            {/* Submit Button */}
            <Button
              type="submit"
              sx={{
                backgroundColor: "var(--dark-blue-button)",
                color: "var(--light-gray)",
                width: "100%",
                height: "40px",
                fontWeight: "600",
                fontSize: "0.875rem",
                borderRadius: "8px",
                marginTop: 2,
                "&:hover": {
                  backgroundColor: "var(--dark-blue-button-hover)",
                },
              }}
            >
              Update
            </Button>
          </FormControl>
        </form>
      </ModalDialog>
    </Modal>
  );
}


UpdateUserAccountModal.propTypes = {
  user: PropTypes.shape({
    full_name: PropTypes.string,
    phone_number: PropTypes.string,
    specialty: PropTypes.string,
    practice: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshUser: PropTypes.func.isRequired,
}