// redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import visitReducer from "./reducers/visitReducer";

export const store = configureStore({
  reducer: {
    visits: visitReducer
  },
});

